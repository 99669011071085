import styled, { css } from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';

interface baseProps {
  align?: string;
  hasError?: any;
  color?: string;
  muted?: boolean;
  roundedBorder?: boolean;
  validationError?: boolean;
  centered?: boolean;
}

type Props = baseProps;

const baseInput = css`
  padding: var(--spacing-y-1);
  &:focus {
    outline: none;
  }
`;

const styledFont = css`
  font-size: clamp(0.0625em, 3vw, 1.5em);
  line-height: 110%;
  text-align: center;
`;

const styledInputFont = css<Props>`
  ${styledFont}
  color: #ffffff;
  ${({ validationError }) => validationError && `color: var(--accent-important)`};
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
    ${({ validationError }) => validationError && `color: var(--accent-important)`};
  }
`;

const borderDefault = css<Props>`
  box-shadow: inset 0px 0px 0px 1px
    ${({ validationError }) => (validationError ? 'var(--accent-important)' : 'rgba(255, 255, 255, 0.1)')};
`;

const borderActive = css<Props>`
  box-shadow: inset 0px 0px 0px 1px
    ${({ validationError }) => (validationError ? 'var(--accent-important)' : '#d5d5d5')};
`;

const borderFocus = css<Props>`
  box-shadow: inset 0px 0px 0px 2px #fff;
`;

export const StyledInput = styled.input<Props>`
  --padding-x: 20px;
  ${styledInputFont}
  ${baseInput}
  padding-left: 20px;
  padding-top: var(--spacing-y-1);
  padding-bottom: var(--spacing-y-1);
  border-style: none;
  text-align: left;
  line-height: 100%;
  letter-spacing: 0.05em;
  background: rgba(255, 255, 255, 0.05);
  ${borderDefault}
  padding: 15px var(--padding-x);
  width: 100%;
  font-weight: normal;
  transition: box-shadow 0.15s ease;
  font-size: clamp(0.0625em, 3vw, 1.1em);
  text-transform: uppercase;

  &:active {
    ${borderActive}
  }

  &:focus {
    ${borderFocus}
  }
`;
