import styled from 'styled-components';
import { device } from 'styles/variables';

export const Container = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
  width: min-content;
  @media (max-height: 430px) and (orientation: landscape) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div<any>`
  padding-bottom: var(--spacing-y-3);
  @media ${device.mobileL} and (orientation: portrait) {
    svg {
      max-width: 325px;
    }
  }

  @media (max-height: 430px) {
    svg {
      max-height: 200px;
      width: auto;
    }
  }
`;

export const Divider = styled.div<any>`
  padding-top: var(--spacing-y-2);
`;

export const ButtonWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-height: 430px) and (orientation: landscape) {
    flex-direction: row;
    width: 75%;
    a,
    button {
      margin: var(--spacing-y-1);
      font-size: 16px;
      padding: 16px 30px;
    }
  }
`;
