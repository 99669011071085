import React, { useState } from 'react';
import Modal from 'components/modal';
import useEventStatus from 'hooks/useEventStatus';
import GlobalStyle from 'styles/globalStyle';
import Form from 'components/form';
import Header from 'components/header';
import Hero from 'components/hero';
import Intro from 'components/intro';
import Teaser from 'components/teaser';
import Footer from 'components/footer';
import EventInProgressOverlay from 'components/eventInProgressOverlay';
import TempEventInProgressOverlay from 'components/tempEventInProgressOverlay';
import { gsap } from 'gsap';

const Page = () => {
  const [modalClosed, setModalClosed] = useState(false);
  const EVENT_ID = '81c027ea-cab4-4d68-8137-9edec832334b';

  const { isOverlayOpen, eventStatus } = useEventStatus(EVENT_ID);

  const onClose = () => {
    setModalClosed(true);
  };

  const scrollToForm = () => {
    gsap.to(window, { duration: 2, scrollTo: '#rsvp-form', offsetY: -50 });
  };

  const onRsvpClick = () => {
    setModalClosed(true);
    scrollToForm();
  };

  return (
    <>
      <GlobalStyle />
      <Modal
        isShowing={!modalClosed && eventStatus === 'SHOW_START'}
        onClose={onClose}
        alignment="CENTER"
      >
        <EventInProgressOverlay onRsvpClick={onRsvpClick} />
      </Modal>
      <Modal isShowing={!modalClosed && isOverlayOpen} onClose={onClose} alignment="CENTER">
        <TempEventInProgressOverlay onRsvpClick={onRsvpClick} />
      </Modal>
      <Hero />
      <Intro />
      <Teaser />
      <Header />
      <Form />
      <Footer />
    </>
  );
};

export default Page;
