/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as FormStyled from 'components/form/form.styled';
import * as Styled from './formCheckbox.styled';

import { ICheckboxProps } from './formCheckbox.types';

const FormCheckbox = ({
  errors,
  register,
  name,
  validation,
  roundedBorder,
  accentColor,
  text,
  requiredMessage,
  centered,
  checkBoxColor,
  ...restProps
}: ICheckboxProps) => {
  return (
    <FormStyled.InputWrapper>
      <Styled.CheckBoxWrapper centered={centered}>
        <Styled.Checkbox
          name={name}
          id={name}
          key={name}
          {...register(name, { ...validation })}
          type="checkbox"
          checkBoxColor={checkBoxColor}
          {...restProps}
        />
        <Styled.Label checkBoxColor={checkBoxColor} htmlFor={name} roundedBorder={roundedBorder} />
        <Styled.CheckBoxText color={accentColor}>{text}</Styled.CheckBoxText>
      </Styled.CheckBoxWrapper>
      {errors[name] && (
        <FormStyled.ErrorMessage align="center">
          {errors[name].message || requiredMessage || 'Please check the box to continue.'}
        </FormStyled.ErrorMessage>
      )}
    </FormStyled.InputWrapper>
  );
};

FormCheckbox.defaultProps = {
  accentColor: '#fff',
  centered: true,
  roundedBorder: false,
  requiredMessage: 'Please check the box to continue.'
};

export default FormCheckbox;
