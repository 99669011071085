import styled, { css } from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';
import Typography from '../typography';

const { H2, PG } = Typography;

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: var(--spacing-y-4) var(--spacing-y-2);
  border: 1px solid var(--red-stroke);
  background: var(--body-bg-color);


  @media ${device('min').mediumLarge} {
    padding: var(--spacing-y-4) var(--spacing-y-4);
  }
`;

export const Heading = styled(H2)<any>`
  font-size: ${`clamp(${pxToEm(22)}em, 6vw, ${pxToEm(40)}em)`};
`;

export const SubHeading = styled(PG)<any>`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: ${`clamp(${pxToEm(18)}em, 6vw, ${pxToEm(30)}em)`};
`;

export const Copy = styled(PG)<any>`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.1em;
  margin-left: auto;
  margin-right: auto;
  font-size: ${`clamp(${pxToEm(16)}em, 6vw, ${pxToEm(18)}em)`};
`;

export const KeyArt = styled.img`
  margin: 0 auto;
  object-fit: cover;
`;

export const KeyArtWrapper = styled.button`
  position: relative;
  margin-bottom: var(--spacing-y-4);
  display: inline-block;
  max-width: 750px;
  cursor: pointer;
  border: none;
  background: none;

  .hovercircle {
    transition: all 0.3s ease;
  }

  &:hover {
    .hovercircle {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  z-index: 5;
  position: relative;

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
  }

  &:after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const pointerHoverContentStyles = (active: any) => css`
  opacity: ${active ? '1' : '0'};
  transition: opacity 0.3s ease 0.15s;
  position: fixed;
  top: -96px;
  left: -96px;
  transform-origin: center center;
  pointer-events: none;
`;

export const HoverWrapper = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: center center;
  will-change: transform;
`;

const mobileHoverContentStyles = css`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const HoverContent = styled.div<any>`
  /* will-change: transform; */
  border: 1px solid var(--red-stroke);
  border-radius: 50%;
  width: 140px;
  height: 140px;
  z-index: 9;

  display: flex;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(12px);
  opacity: 1;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  /* Fallback for Firefox */
  background-color: rgba(0, 0, 0, 0.45);

  /* if backdrop support: transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(12px);
    background-color: transparent;
  }

  h3 {
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  @media ${device('min').mediumLarge} {
    width: 192px;
    height: 192px;

    h3 {
      font-size: 22px;
      line-height: 24px;
    }
  }
`;
