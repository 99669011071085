import styled, { css } from 'styled-components';
import { device } from 'styles/styleFunctions';
import { focusBox, flexbox } from 'styles/variables';

import { hexToRGBA } from 'utils/color';
import { ICheckboxProps } from './formCheckbox.types';

interface BaseProps {
  color?: string;
  checkBoxColor?: string;
}

type Props = Pick<ICheckboxProps, 'roundedBorder' | 'centered'> & BaseProps;

type StyleProps = Props;

const styledFont = css`
  font-size: clamp(0.0625em, 3vw, 0.9em);
  line-height: 120%;
  text-align: left;
  text-transform: uppercase;

  @media ${device('min').mediumLarge} {
    // styles for 1220px wide viewport and UP
    text-align: center;
  }

`;

export const Checkbox = styled.input<Props>`
  opacity: 0;
  margin: 6px;
  height: 20px;
  width: 20px;
  position: absolute;
  &:checked ~ label {
    &:after {
      transform: rotate(0deg) scale(1);
      ${({ checkBoxColor }) => checkBoxColor && `background-color: ${checkBoxColor}`};
    }
  }

  &:focus + label::before {
    outline: 0;
    box-shadow: ${focusBox};
  }
`;

export const CheckBoxText = styled.p`
  ${styledFont};
  color: rgba(255, 255, 255, 0.8);
  ${({ color }) => color && `color: ${hexToRGBA(color, 0.8)};`}
  margin: 0;

  & a {
    text-decoration: underline;
    color: white;
  }
`;

export const Label = styled.label<StyleProps>`
  font-size: clamp(1.0625em, 3vw, 1.5em);
  position: relative;
  min-height: 36px;
  box-sizing: content-box;
  display: block;
  padding-left: 30px;
  margin-bottom: 0;
  margin-right: 10px;
  font-weight: normal;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 6px;
    width: 20px;
    height: 20px;
    transition: transform 0.05s ease;
    border: 1px solid ${({ color }) => (color ? hexToRGBA(color, 0.35) : 'rgba(255,255,255,0.35)')};
    ${({ roundedBorder }) => (!roundedBorder ? '' : 'border-radius: 3px;')}
    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: ${({ color }) => color || '#ffffff'};
    ${({ checkBoxColor }) => checkBoxColor && `background-color: ${checkBoxColor}`};
    transform: rotate(0deg) scale(0);
    transition: transform ease 0.05s;
    position: absolute;
    top: 9px;
    left: 9px;
    ${({ roundedBorder }) => (!roundedBorder ? '' : 'border-radius: 2px;')}
  }
`;

export const CheckBoxWrapper = styled.div<StyleProps>`
  padding-top: var(--spacing-y-1);
  padding-bottom: var(--spacing-y-1);
  ${({ centered }) => (centered ? flexbox.spaceCenter : flexbox.spaceStart)};
`;
