import styled from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';
import Typography from '../typography';
import Container from 'components/container';

const { H1 } = Typography;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding-bottom: var(--spacing-y-9);
  padding-top: 67px;
  display: flex;
  align-items: center;

  min-height: -webkit-fill-available;

  @media ${device('min').mediumLarge} {
    min-height: 100vh;
  }

  @media only screen and (min-width: 1560px) {
    font-size: 132%;
  }

  @media only screen and (min-width: 1820px) {
    font-size: 142.5%;
  }
`;

export const HeroBg = styled.div`
  width: 100%;
  height: 100%;
  /* max-width: 1440px;
  max-height: 837px; */
  background-image: url('https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/hero-skeleton%402x.jpg');
  background-position: top center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const HeroHeading = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  /* padding-top: clamp(400px, 90vh, 837px); */
  /* padding-top: 100vh; */
  /* padding-top: 67px;

  @media ${device('min').large} {
    // styles for 1440px wide viewport and UP
    padding-top: clamp(100px, 10vh, 837px);
  } */

  /* padding-top: 91px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
`;

export const Welcome = styled(H1)`
  font-size: ${`clamp(${pxToEm(33)}em, 6vw, ${pxToEm(68)}em)`};
  margin: clamp(10px, 5vh, 85px) 0;
  transform: translateX(-8vw);
  overflow-y: hidden;
  text-align: center;
`;

export const Tothe = styled(H1)`
  font-size: ${`clamp(${pxToEm(22)}em, 4vw, ${pxToEm(42)}em)`};
  margin: clamp(10px, 5vh, 85px) 0;
  transform: translateX(6vw);
  overflow-y: hidden;
  text-align: center;
  opacity: 0.7;
`;

export const Blumhouse = styled(H1)`
  font-size: ${`clamp(${pxToEm(20)}em, 8vw, ${pxToEm(78)}em)`};
  margin: clamp(10px, 5vh, 85px) 0;
  color: var(--red);
  transform: translateX(4vw);
  overflow-y: hidden;
  text-align: center;

  @media ${device('min').large} {
    // styles for 1440px wide viewport and UP
    transform: translateX(10vw);
  }
`;

export const Live = styled(H1)`
  font-size: ${`clamp(${pxToEm(61)}em, 16vw, ${pxToEm(164)}em)`};
  margin: clamp(10px, 5vh, 85px) 0;
  transform: translateX(-20vw);
  overflow-y: hidden;
  text-align: center;
  /* -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fff;
  color: transparent; */
`;

export const CtaContainer = styled(Container)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: var(--spacing-y-4);
  padding: var(--spacing-y-2) var(--spacing-y-3);
`;

export const CTA = styled.button`
  /* margin-top: var(--spacing-y-6); */
  padding-bottom: 10px;
  line-height: 110%;
  text-align: right;
  background: none;
  border: none;

  color: #fff;
  padding-left: 0;
  padding-right: 0;

  font-size: ${`clamp(${pxToEm(14)}em, 5vw, ${pxToEm(27)}em)`};

  span.rsvp {
    color: var(--red);
    border-bottom: 2px solid var(--red);
  }

  &:hover {
    span {
      color: #fff;
      border-color: #fff;
    }
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;
