/* eslint-disable react/jsx-props-no-spreading  */
import React, { ReactNode } from 'react';

import * as Styled from './button.styled';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isLightBg?: boolean;
  isOutline?: boolean;
  bgColor?: string;
  color?: string;
  hoverColor?: string;
  size?: 'small' | 'default' | 'large';
  children: ReactNode;
  as?: React.ElementType;
  target?: string;
  ariaLabel?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
}

const Button = React.forwardRef(
  (
    {
      children,
      isLightBg,
      color,
      bgColor,
      hoverColor,
      isOutline,
      size,
      href,
      target,
      role,
      ariaLabel,
      ...restProps
    }: IButtonProps & React.HTMLProps<HTMLAnchorElement>,
    ref: any
  ) => {
    const defaultColor = isLightBg ? 'var(--accent-dark)' : 'var(--light)';
    const defaultBgColor = isLightBg ? 'var(--light)' : 'var(--accent-dark)';

    return (
      <Styled.Button
        ref={ref}
        $as={href ? 'a' : 'button'}
        href={href}
        target={href && target ? target : undefined}
        isOutline={isOutline}
        isLightBg={isLightBg}
        hoverColor={hoverColor}
        color={color || defaultColor}
        bgColor={bgColor || defaultBgColor}
        size={size || 'default'}
        role={role || 'button'}
        aria-label={ariaLabel || undefined}
        {...restProps}
      >
        {children}
      </Styled.Button>
    );
  }
);

Button.defaultProps = {
  isLightBg: false,
  isOutline: false,
  bgColor: undefined,
  color: undefined,
  hoverColor: undefined,
  as: undefined,
  size: undefined,
  target: undefined,
  ariaLabel: undefined
};

export default Button;
