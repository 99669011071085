/* eslint-disable react/jsx-props-no-spreading  */
import React from 'react';

import * as Styled from './closeButton.styled';

interface ICloseButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isLightBg?: boolean;
  color?: string;
}

const CloseIcon = ({ color, isLightBg }: ICloseButtonProps) => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill={isLightBg ? '#fff' : color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97135 0.863281L0.541992 9.29264L24.5954 33.346L0.542351 57.3991L8.97171 65.8284L33.0247 41.7754L57.0775 65.8281L65.5068 57.3988L41.4541 33.346L65.5072 9.29296L57.0778 0.863602L33.0247 24.9167L8.97135 0.863281Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97135 0.863281L0.541992 9.29264L24.5954 33.346L0.542351 57.3991L8.97171 65.8284L33.0247 41.7754L57.0775 65.8281L65.5068 57.3988L41.4541 33.346L65.5072 9.29296L57.0778 0.863602L33.0247 24.9167L8.97135 0.863281Z"
      fill={color}
      className="inner-fill"
    />
    <path
      d="M0.541992 9.29264L-0.165115 8.58553L-0.872221 9.29264L-0.165115 9.99975L0.541992 9.29264ZM8.97135 0.863281L9.67846 0.156174L8.97135 -0.550932L8.26425 0.156174L8.97135 0.863281ZM24.5954 33.346L25.3025 34.0531L26.0096 33.346L25.3025 32.6389L24.5954 33.346ZM0.542351 57.3991L-0.164756 56.692L-0.871863 57.3991L-0.164756 58.1062L0.542351 57.3991ZM8.97171 65.8284L8.2646 66.5355L8.97171 67.2427L9.67882 66.5355L8.97171 65.8284ZM33.0247 41.7754L33.7319 41.0683L33.0247 40.3612L32.3176 41.0683L33.0247 41.7754ZM57.0775 65.8281L56.3704 66.5352L57.0775 67.2423L57.7846 66.5352L57.0775 65.8281ZM65.5068 57.3988L66.2139 58.1059L66.921 57.3988L66.2139 56.6917L65.5068 57.3988ZM41.4541 33.346L40.747 32.6389L40.0399 33.346L40.747 34.0531L41.4541 33.346ZM65.5072 9.29296L66.2143 10.0001L66.9214 9.29296L66.2143 8.58586L65.5072 9.29296ZM57.0778 0.863602L57.7849 0.156496L57.0778 -0.550611L56.3707 0.156496L57.0778 0.863602ZM33.0247 24.9167L32.3176 25.6238L33.0247 26.3309L33.7319 25.6238L33.0247 24.9167ZM1.2491 9.99975L9.67846 1.57039L8.26425 0.156174L-0.165115 8.58553L1.2491 9.99975ZM25.3025 32.6389L1.2491 8.58553L-0.165115 9.99975L23.8883 34.0531L25.3025 32.6389ZM1.24946 58.1062L25.3025 34.0531L23.8883 32.6389L-0.164756 56.692L1.24946 58.1062ZM9.67882 65.1213L1.24946 56.692L-0.164756 58.1062L8.2646 66.5355L9.67882 65.1213ZM32.3176 41.0683L8.2646 65.1213L9.67882 66.5355L33.7319 42.4825L32.3176 41.0683ZM57.7846 65.121L33.7319 41.0683L32.3176 42.4825L56.3704 66.5352L57.7846 65.121ZM64.7997 56.6917L56.3704 65.121L57.7846 66.5352L66.2139 58.1059L64.7997 56.6917ZM40.747 34.0531L64.7997 58.1059L66.2139 56.6917L42.1612 32.6389L40.747 34.0531ZM64.8001 8.58586L40.747 32.6389L42.1612 34.0531L66.2143 10.0001L64.8001 8.58586ZM56.3707 1.57071L64.8001 10.0001L66.2143 8.58586L57.7849 0.156496L56.3707 1.57071ZM33.7319 25.6238L57.7849 1.57071L56.3707 0.156496L32.3176 24.2096L33.7319 25.6238ZM8.26425 1.57039L32.3176 25.6238L33.7319 24.2096L9.67846 0.156174L8.26425 1.57039Z"
      fill={color}
      mask="url(#path-1-inside-1)"
      className="outer-stroke"
    />
  </svg>
);

const CloseButton = React.forwardRef(
  ({ color, isLightBg, ...restProps }: ICloseButtonProps, ref: any) => {
    return (
      <Styled.Close ref={ref} {...restProps}>
        <CloseIcon color={color} isLightBg={isLightBg} />
      </Styled.Close>
    );
  }
);

CloseButton.defaultProps = {
  isLightBg: true,
  color: 'var(--off-white)'
};

CloseIcon.defaultProps = {
  isLightBg: true,
  color: 'var(--off-white)'
};

export default CloseButton;
