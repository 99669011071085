/* eslint-disable guard-for-in  */
/* eslint-disable no-restricted-syntax  */
import { ScrollTrigger } from 'gsap/all';

const killAnimations = (tlRefs: any) => {
  // kill the timeline
  if (tlRefs.current.length > 0) {
    for (const currRef in tlRefs.current) {
      const ref = tlRefs.current[currRef];
      ref.kill();
    }

    // kill the scrolltriggers
    tlRefs.current.forEach((scrollTrigger: any, i: number) => {
      const scrollTriggerRef = ScrollTrigger.getById(`scrollTrigger${i}`);
      if (scrollTriggerRef) {
        scrollTriggerRef.kill(true);
      }
    });
  }
};

export default killAnimations;
