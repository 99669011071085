import styled from 'styled-components';
import { device } from 'styles/styleFunctions';

export const HeaderSection = styled.div`
  padding: var(--spacing-y-2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RsvpButton = styled.button`
  font-size: 19.7879px;
  line-height: 23px;
  text-align: right;
  background: none;
  border: none;
  border-bottom: 2px solid var(--red);

  color: var(--red);
  padding-left: 0;
  padding-right: 0;

  font-size: clamp(0.9375em, 5vw, 1.1875em);

  @media ${device('max').small} {
    // styles for 767px wide viewport and DOWN
    span {
      display: none;
    }
  }

  &:hover {
    color: #fff;
    border-color: #fff;
    /* border: none; */

    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;
