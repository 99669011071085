/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Close = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  justify-content: flex-end;
  display: flex;
  z-index: 100;
  width: 54px;
  height: 54px;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;

  .path-inside-1 {
    fill: white;
    fill-opacity: 1;
  }

  .inner-fill {
    fill-opacity: 0;
  }

  .outer-stroke {
    fill-opacity: 1;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      .inner-fill {
        fill-opacity: 1;
      }
    }
  }
`;
