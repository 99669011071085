/* eslint-disable react/jsx-props-no-spreading  */
import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { RoughEase } from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import Typography from 'components/typography';
import Container from 'components/container';

import * as Styled from './hero.styled';

const { H1 } = Typography;

const Hero = () => {
  const heroBgRef = useRef(null);
  const wrapperRef = useRef(null);

  // text refs
  const welcomeRef = useRef(null);
  const totheRef = useRef(null);
  const blumhouseRef = useRef(null);
  const liveRef = useRef(null);
  const ctaRef = useRef(null);

  const tlRef = useRef<any>();
  const tlTextRef = useRef<any>();

  const tlIntroRef = useRef<any>();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollToPlugin);

    gsap.set([heroBgRef.current], {
      opacity: 0
    });
    // gsap.set([ctaRef.current], {
    //   opacity: 1,
    //   skew: 7
    // });

    let tl = tlRef.current;
    let tlText = tlTextRef.current;
    let tlIntro = tlIntroRef.current;

    let splitWelcome = new SplitText(welcomeRef.current, { type: 'words, chars', charsClass: 'point' });
    let splitTothe = new SplitText(totheRef.current, { type: 'words, chars', charsClass: 'point' });
    let splitBlumhouse = new SplitText(blumhouseRef.current, { type: 'words, chars', charsClass: 'point' });
    let splitLive = new SplitText(liveRef.current, { type: 'words, chars', charsClass: 'point' });
    // let splitCta = new SplitText(ctaRef.current, { type: 'words, chars', charsClass:"point" });

    const tlSplit = gsap.timeline({ delay: 2 });

    tlSplit.from(splitWelcome.chars, {
      duration: 1.8,
      y: '110%',
      ease: 'power4.out',
      skewY: 7,
      stagger: {
        amount: 0.3
      }
    });
    tlSplit.from(
      splitTothe.chars,
      {
        duration: 1.8,
        y: '110%',
        ease: 'power4.out',
        skewY: 7,
        stagger: {
          amount: 0.3
        }
      },
      '-=1.7'
    );
    tlSplit.from(
      splitBlumhouse.chars,
      {
        duration: 1.8,
        y: '110%',
        ease: 'power4.out',
        skewY: 7,
        stagger: {
          amount: 0.3
        }
      },
      '-=1.7'
    );
    tlSplit.from(
      splitLive.chars,
      {
        duration: 1.8,
        y: '110%',
        ease: 'power4.out',
        skewY: 7,
        stagger: {
          amount: 0.3
        }
      },
      '-=1.7'
    );
    tlSplit.from(
      ctaRef.current,
      {
        duration: 1.8,
        opacity: 0,
        y: '110%',
        skewY: 7,
        ease: 'power4.out'
      },
      '-=1.7'
    );

    // //now animate each character into place from 100px above, fading in:
    // gsap.from(split.chars, { duration: 1, y: 100, autoAlpha: 0, stagger: 0.05 });
    // //or animate each word
    // gsap.from(split.words, { duration: 1, x: 200, autoAlpha: 0, ease: 'elastic', stagger: 0.05 });
    // //or animate each line
    // gsap.from(split.lines, { duration: 1, x: 200, autoAlpha: 0, ease: 'power3', stagger: 0.05 });

    tlIntro = gsap.timeline();
    tlIntro.to([heroBgRef.current], {
      duration: 2,
      opacity: 1,
      ease: RoughEase.ease.config({ points: 30, strength: 2, clamp: true })
    });
    tlIntro.to(heroBgRef.current, {
      duration: 2.5,
      opacity: 0.05
    });
    tlIntro.play();

    tl = gsap.timeline({
      scrollTrigger: {
        id: `scrollTrigger1`,
        trigger: wrapperRef.current,
        start: 'top top',
        end: 'bottom end',
        // markers: true,
        toggleActions: 'restart pause reverse pause', // onEnter, onLeave, onEnterBack, onLeaveBack
        // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
        scrub: true
      }
    });

    tl.to([heroBgRef.current], {
      opacity: 0.035,
      duration: 1,
      ease: 'power4.out'
    });

    tlText = gsap.timeline({
      scrollTrigger: {
        id: `scrollTrigger2`,
        trigger: wrapperRef.current,
        start: 'top top',
        end: 'bottom end',
        // markers: true,
        toggleActions: 'play none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
        scrub: true
      }
    });

    tlText.to(
      [welcomeRef.current],
      {
        x: '-60%',
        y: 0,
        opacity: 0.05,
        ease: 'power1.out'
      },
      0
    );
    tlText.to(
      [totheRef.current],
      {
        x: '140%',
        y: 0,
        opacity: 0.05,
        ease: 'power1.out'
      },
      '-=0.5'
    );
    tlText.to(
      [blumhouseRef.current],
      {
        x: '50%',
        y: 0,
        opacity: 0.05,
        ease: 'power1.out'
      },
      '-=0.5'
    );
    tlText.to(
      [liveRef.current],
      {
        x: '-35%',
        y: 0,
        opacity: 0.05,
        ease: 'power1.out'
      },
      '-=0.5'
    );
  }, []);

  const scrollToForm = () => {
    gsap.to(window, {duration: 2, scrollTo: "#rsvp-form", offsetY: -50});
  }

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.HeroBg ref={heroBgRef} />
      <Styled.HeroHeading>
        <Container hasMaxWidth hasHorizontalPadding maxWidth="1870px">
          <Styled.Welcome hasMargin ref={welcomeRef}>
            Welcome
          </Styled.Welcome>
          <Styled.Tothe hasMargin ref={totheRef}>
            to the
          </Styled.Tothe>
          <Styled.Blumhouse hasMargin ref={blumhouseRef}>
            Blumhouse
          </Styled.Blumhouse>
          <Styled.Live hasMargin ref={liveRef}>
            Live
          </Styled.Live>
        </Container>
      </Styled.HeroHeading>
      <Styled.CtaContainer>
        <Styled.CTA ref={ctaRef} onClick={scrollToForm}>
          OCT 14{' '}
          <span className="rsvp">
            RSVP NOW{' '}
            <svg width="37" height="15" viewBox="0 0 37 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.4866 1.27438L34.6738 7.46156L28.4866 13.6487" stroke="#BB131A" strokeWidth="2.58621" />
              <path d="M34.4492 7.46158L0.51704 7.46157" stroke="#CD2B1E" strokeWidth="2.58621" />
            </svg>
          </span>
        </Styled.CTA>
      </Styled.CtaContainer>
    </Styled.Wrapper>
  );
};

export default Hero;
