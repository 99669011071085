import { useEffect, useRef } from 'react';
import gsap from 'gsap';

interface ICallbackProps {
  onStart?: () => void;
  onReverseComplete?: () => void;
}
interface IUseOverlayAnimationProps {
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  overlayRef: React.MutableRefObject<HTMLDivElement | null>;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
  callbacks: ICallbackProps;
}

const useOverlayAnimation = ({
  wrapperRef,
  overlayRef,
  contentRef,
  callbacks
}: IUseOverlayAnimationProps) => {
  const tlRef = useRef<any>(null);

  useEffect(() => {
    // dont create more than one timeline
    if (tlRef.current) return;

    gsap.set(wrapperRef.current, {
      opacity: 0
    });

    gsap.set(overlayRef.current, {
      opacity: 0
    });

    gsap.set(contentRef.current, {
      y: 30,
      opacity: 0
    });

    // intialize the timeline
    tlRef.current = gsap.timeline({
      onStart: callbacks.onStart ? callbacks.onStart : undefined,
      onReverseComplete: callbacks.onReverseComplete ? callbacks.onReverseComplete : undefined
    });

    // setup the timeline animations
    tlRef.current
      .to(wrapperRef.current, {
        y: 0,
        opacity: 1,
        duration: 1.65,
        ease: 'power4.out'
      })
      .to(
        overlayRef.current,
        {
          opacity: 1,
          duration: 0.65,
          ease: 'power4.out'
        },
        '-=1.65'
      )
      .to(
        contentRef.current,
        {
          y: 0,
          opacity: 1,
          duration: 1.65,
          ease: 'power4.out'
        },
        '-=1'
      );

    tlRef.current.pause();
  }, [wrapperRef, overlayRef, contentRef, callbacks]);

  return tlRef;
};

export default useOverlayAnimation;
