export const breakpoints: any = {
  xsmall: 375,
  small: 768,
  medium: 1024,
  mediumLarge: 1220,
  large: 1440,
  xlarge: 1680,
  xxlarge: 1920
};

/* --------------------------------//
    EXAMPLE USAGE
  ---------------------------------//

  @media ${device('min').large} {
    // styles for 1440px wide viewport and UP
  }

  @media ${device('max').small} {
    // styles for 767px wide viewport and DOWN
  }

------------------------------------*/

export const device = (type: 'min' | 'max') =>
  Object.keys(breakpoints).reduce((bp: any, size: any) => {
    const width = type === 'max' ? breakpoints[size] - 1 : breakpoints[size];
    const breakpoint = bp;
    breakpoint[size] = `(${type}-width: ${width}px)`;
    return breakpoint;
  }, {});

/**
 * `clampBuilder` builds a clamp based css style with a
 * min and max font size and min and max viewport width
 * @param minWidthPx minimum width of the viewport in pixels where you want fluid type
 * @param maxWidthPx maximum width of the viewport in pixels where you want fluid type
 * @param minFontSize the static size the font will be below the `minWidthPx`
 * @param maxFontSize the static size the font will be below the `maxWidthPx`
 *
 * @returns void
 * Example: `font-size: ${clampBuilder(1024, 1439, 3.5, 4.2)};`
 */
export function clampBuilder(minWidthPx: number, maxWidthPx: number, minFontSize: number, maxFontSize: number) {
  const root: HTMLElement | null = document.querySelector('html');
  const pixelsPerRem = Number(getComputedStyle(root as Element).fontSize.slice(0, -2));

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxFontSize}rem )`;
}

export function pxToEm(px: number, element?: any) {
  element = element === null || element === undefined ? document.body : element;
  var temporaryElement = document.createElement('div');
  temporaryElement.style.setProperty('position', 'absolute', 'important');
  temporaryElement.style.setProperty('visibility', 'hidden', 'important');
  temporaryElement.style.setProperty('font-size', '1em', 'important');
  element.appendChild(temporaryElement);
  var baseFontSize = parseFloat(getComputedStyle(temporaryElement).fontSize);
  temporaryElement?.parentNode?.removeChild(temporaryElement);
  return px / baseFontSize;
}
