/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import parse from 'html-react-parser';
import { gsap } from 'gsap';
import { useForm } from 'react-hook-form';
import TextInput from '../textInput';
import FormCheckbox from '../formCheckbox';
import Button from '../button';
import Container from '../container';
import PageSection from '../pageSection';

import emailRegex from 'utils/regex';

import * as Styled from './form.styled';

const LIVE_URL = 'https://welcometotheblumhouse.live';

const TERMS_URL =
  'https://www.amazon.com/gp/help/customer/display.html/ref=hp_left_v4_sib?ie=UTF8&nodeId=GLSBYFE9MGKKQXXM';
const START_TODAY =
  'https://www.amazon.com/b/ref=atv_gs_intro_prime_b?_encoding=UTF8&node=2858778011';

// New Endpoint
const RSVP_POST_URL = 'https://7wu5owkqv6.execute-api.us-east-1.amazonaws.com/production/postRSVP';

// Old Endpoint
// const RSVP_POST_URL = 'https://j7gyd5f8d1.execute-api.us-east-1.amazonaws.com/local/sendrsvp';

const EVENT_ID = '1ad43c44-bccd-458a-8b14-38c5931d09ec';

const CALENDAR_LINK = isMobile
  ? 'https://calendar.google.com/calendar/ical/c_rasm49t0uhutmjveajdrf623uc%40group.calendar.google.com/public/basic.ics'
  : 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MWhmM3BndDQ0NjNmaGxzdTJucnN0YjBzYW0gY19yYXNtNDl0MHVodXRtanZlYWpkcmY2MjN1Y0Bn&tmsrc=c_rasm49t0uhutmjveajdrf623uc%40group.calendar.google.com';

// Share hrefs
const FACEBOOK_SHARE = `https://www.facebook.com/sharer/sharer.php?href=${LIVE_URL}`;
const TWITTER_SHARE = `http://twitter.com/share?text=I’m going to the %23WelcomeToTheBlumhouse LIVE experience on October 14! Join me by grabbing a Free RSVP here: &url=${LIVE_URL}&hashtags=#WelcomeToTheBlumhouse`;
const EMAIL_SHARE = `mailto:?subject=I’m going to the #WelcomeToTheBlumhouse LIVE experience on October 14!&amp;&body=Join me by grabbing a Free RSVP here:%0D%0A%0D%0A ${LIVE_URL}`;

export type InitialFormSchema = {
  name: string;
  email: string;
  confirmEmail: string;
};

export interface DynamicFormSchema<T> {
  [key: string]: T;
}

export type FormSchema = InitialFormSchema & DynamicFormSchema<any>;

const Form = ({}: any) => {
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const [rsvpState, setRsvpState] = useState<'open' | 'closed' | 'success'>('open');

  const tlRef = useRef<any>();
  const successRef = useRef(null);
  const openRef = useRef(null);
  const closedRef = useRef(null);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<FormSchema>();

  const onSubmit = async ({ email, name, agreement, confirmEmail, ...rest }: FormSchema) => {
    setSubmittingForm(true);
    if (!agreement || !confirmEmail) return;

    const body = {
      email: email.toLowerCase(),
      name,
      rsvpFlag: true,
      eventID: EVENT_ID
    };

    axios({
      method: 'post',
      url: RSVP_POST_URL,
      data: body,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        //handle success
        setSubmittingForm(false);
        setRsvpState('success');
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 406) {
            setRsvpState('closed');
          }
        }
        //handle error
        console.log('error response', error);
      });
  };

  const emailValidation = {
    required: true,
    pattern: {
      value: emailRegex,
      message: 'Entered value does not match email format'
    },
    validate: {
      emailEqual: (value: any) =>
        value.toLowerCase() === getValues().email.toLowerCase() || 'Emails do not match'
    }
  };

  useEffect(() => {
    axios({
      url: 'https://api.lcdigital.io/graphql',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        query: `query{rsvpCount(eventID:"${EVENT_ID}")}`,
        variables: {}
      }
    })
      .then(function (response) {
        //handle success

        // rsvps greater than 50k
        if (response.data.data.rsvpCount >= 50000) {
          // if (response.data.data.rsvpCount >= 2) {
          setRsvpState('closed');
        }
      })
      .catch(function (response) {
        //handle error
        console.log('error response', response);
      });
  }, []);

  useEffect(() => {
    let tl = tlRef.current;
    tl = gsap.timeline({});

    if (rsvpState === 'success') {
      tl.to(openRef.current, {
        duration: 1,
        opacity: 0
      });
      tl.from(
        successRef.current,
        {
          duration: 1,
          opacity: 0
        },
        '-=0.65'
      );
    }

    if (rsvpState === 'closed') {
      tl.to(openRef.current, {
        duration: 1,
        opacity: 0
      });
      tl.from(
        closedRef.current,
        {
          duration: 1,
          opacity: 0
        },
        '-=0.65'
      );
    }
  }, [rsvpState]);

  return (
    <PageSection hasPaddingTop={false} id="rsvp-form">
      <Container hasMaxWidth>
        <Styled.FormWrapper className="anim-in">
          {rsvpState === 'success' && (
            <Styled.Success ref={successRef}>
              <Styled.HeaderGroup>
                {/* <Styled.SupHeading>WELCOME TO THE</Styled.SupHeading> */}
                <Styled.SuccessHeading hasMargin margin="0.075em 0 0 0">
                  check your email for more information
                </Styled.SuccessHeading>
                {/* <Styled.SubHeading>check your email for more information</Styled.SubHeading> */}
                <Styled.ShareWrapper>
                  <Styled.PGSmall>Share with your friends</Styled.PGSmall>
                  <Styled.ShareLinks>
                    <Styled.ShareLink href={FACEBOOK_SHARE} target="_blank">
                      <svg
                        width="33"
                        height="41"
                        viewBox="0 0 33 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.0257 11.2511C21.3335 11.1171 20.3985 11.0171 19.8105 11.0171C18.2184 11.0171 18.1149 11.6866 18.1149 12.7579V14.6648H22.0949L21.748 18.615H18.1149V30.6301H13.1316V18.615H10.5703V14.6648H13.1316V12.2215C13.1316 8.87465 14.758 7 18.8414 7C20.2601 7 21.2984 7.20086 22.6479 7.46866L22.0257 11.2511Z"
                          fill="var(--red)"
                          stroke="var(--red)"
                          strokeWidth="0.525113"
                        />
                      </svg>
                    </Styled.ShareLink>
                    <Styled.ShareLink href={TWITTER_SHARE} target="_blank" className="twitter">
                      <svg
                        width="46"
                        height="39"
                        viewBox="0 0 46 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.8529 8.08692C34.7557 8.85785 33.541 9.44749 32.2554 9.83313C31.5654 9.04278 30.6483 8.4826 29.6283 8.22835C28.6083 7.97411 27.5345 8.03806 26.5522 8.41157C25.5698 8.78507 24.7263 9.4501 24.1358 10.3167C23.5452 11.1833 23.2361 12.2097 23.2502 13.2571V14.3984C21.2367 14.4504 19.2417 14.0056 17.4426 13.1035C15.6436 12.2014 14.0964 10.8702 12.9389 9.22823C12.9389 9.22823 8.35606 19.5001 18.6674 24.0653C16.3078 25.6608 13.497 26.4609 10.6475 26.348C20.9588 32.0545 33.5615 26.348 33.5615 13.2228C33.5604 12.9049 33.5297 12.5878 33.4698 12.2755C34.6391 11.1268 35.4643 9.67644 35.8529 8.08692Z"
                          fill="var(--red)"
                        />
                      </svg>
                    </Styled.ShareLink>
                    <Styled.ShareLink href={EMAIL_SHARE} target="_blank">
                      <svg
                        width="29"
                        height="17"
                        viewBox="0 0 29 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.9748 0.0749512C2.57221 0.0783813 2.1798 0.184567 1.84698 0.380136L13.7898 10.1461C13.9008 10.2473 14.0346 10.3279 14.1829 10.383C14.3312 10.4381 14.491 10.4665 14.6525 10.4665C14.8139 10.4665 14.9737 10.4381 15.122 10.383C15.2703 10.3279 15.4041 10.2473 15.5151 10.1461L27.4367 0.380136C27.1039 0.184567 26.7115 0.0783813 26.3089 0.0749512H2.9748ZM0.987864 1.33231C0.901517 1.53787 0.856093 1.75456 0.853516 1.9732V14.6567C0.854225 15.0256 0.976877 15.3866 1.20706 15.6974L9.63562 8.41871L0.987864 1.33231ZM28.2958 1.33231L19.6693 8.40956L28.0766 15.6974C28.3068 15.3866 28.4295 15.0256 28.4302 14.6567V1.9732C28.4276 1.75456 28.3822 1.53787 28.2958 1.33231ZM18.6405 9.26713L16.4874 11.0342C15.9912 11.4385 15.3348 11.6637 14.6525 11.6637C13.9701 11.6637 13.3137 11.4385 12.8175 11.0342L10.6644 9.27629L2.31013 16.4603C2.52425 16.5229 2.74876 16.5548 2.9748 16.555H26.3089C26.5349 16.5548 26.7594 16.5229 26.9736 16.4603L18.6405 9.26713Z"
                          fill="var(--red)"
                        />
                      </svg>
                    </Styled.ShareLink>
                    <Styled.ShareLink href={CALENDAR_LINK} target="_blank">
                      <svg
                        width="25"
                        height="23"
                        viewBox="0 0 25 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.20446 0.477051H8.57628V3.84901H5.20446V0.477051ZM19.6551 0.477051V3.84901H16.2833V0.477051H19.6551ZM4.24108 3.84901V1.92225H0.387573V7.70252H24.472V1.92225H20.6185V3.84901C20.6185 4.37886 20.185 4.81238 19.6551 4.81238H16.2833C15.7534 4.81238 15.3199 4.37886 15.3199 3.84901V1.92225H9.53966V3.84901C9.53966 4.37886 9.10614 4.81238 8.57628 4.81238H5.20446C4.6746 4.81238 4.24108 4.37886 4.24108 3.84901ZM0.387573 8.66589V22.1532H24.472V8.66589H0.387573ZM16.765 14.9278H12.9115V11.0743H11.9481V14.9278H8.09459V15.8912H11.9481V19.7447H12.9115V15.8912H16.765V14.9278Z"
                          fill="#CD2B1E"
                        />
                      </svg>
                    </Styled.ShareLink>
                  </Styled.ShareLinks>
                </Styled.ShareWrapper>
                <Styled.CalloutWrapper>
                  <Styled.PGSmall>
                    WATCH THE FILMS ON PRIME VIDEO. START YOUR FREE TRIAL TODAY
                  </Styled.PGSmall>
                  <Styled.CalloutBtn
                    isLightBg={false}
                    bgColor={'var(--red-stroke)'}
                    href={START_TODAY}
                    target="_blank"
                  >
                    start today
                  </Styled.CalloutBtn>
                </Styled.CalloutWrapper>
              </Styled.HeaderGroup>
            </Styled.Success>
          )}

          {rsvpState === 'closed' && (
            <Styled.Closed ref={closedRef}>
              <Styled.HeaderGroup>
                <Styled.Heading>RSVP CLOSED</Styled.Heading>
                <Styled.SubHeading hasMargin>The show is sold out</Styled.SubHeading>
                <Styled.CalloutWrapper>
                  <Styled.PGSmall>
                    WATCH THE FILMS ON PRIME VIDEO. START YOUR FREE TRIAL TODAY
                  </Styled.PGSmall>
                  <Styled.CalloutBtn
                    isLightBg={false}
                    bgColor={'var(--red-stroke)'}
                    href={START_TODAY}
                    target="_blank"
                  >
                    start today
                  </Styled.CalloutBtn>
                </Styled.CalloutWrapper>
              </Styled.HeaderGroup>
            </Styled.Closed>
          )}

          <Styled.Open ref={openRef}>
            <Styled.HeaderGroup>
              <Styled.Heading>RSVP NOW</Styled.Heading>
              <Styled.SubHeading hasMargin>
                oct 14 • 6:00 PST • Limited tickets available
              </Styled.SubHeading>
            </Styled.HeaderGroup>

            <Styled.Form onSubmit={handleSubmit(onSubmit)}>
              <Styled.InputGroup centered>
                <TextInput
                  name="name"
                  autoComplete="off"
                  placeholder="Enter Your Name"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                    minLength: 2
                  }}
                  roundedBorder
                />

                <TextInput
                  name={'email'}
                  autoComplete="off"
                  placeholder={'Enter Email Address'}
                  roundedBorder
                  register={register}
                  errors={errors}
                  validation={emailValidation}
                />

                <TextInput
                  name="confirmEmail"
                  autoComplete="off"
                  placeholder={'Confirm Email Address'}
                  roundedBorder
                  register={register}
                  errors={errors}
                  validation={emailValidation}
                />

                <FormCheckbox
                  name="agreement"
                  centered
                  register={register}
                  validation={{
                    required: true
                  }}
                  checkBoxColor="var(--red)"
                  errors={errors}
                  roundedBorder
                  // checkBoxColor={isNetflix ? NetflixColor.Red : undefined}
                  text={parse(
                    `I agree to the <a href="${TERMS_URL}" target="_blank">Terms and Policy</a>`
                  )}
                  requiredMessage="Please check the box to continue"
                />
              </Styled.InputGroup>
              <Button
                htmlType="submit"
                isLoading={submittingForm}
                isLightBg={false}
                bgColor={'var(--red-stroke)'}
              >
                Submit
              </Button>
            </Styled.Form>
          </Styled.Open>
        </Styled.FormWrapper>

        {/* <Styled.Disclaimer hasMargin>
          Your email will only exclusively be used to send you information about the show and your acesss code day of
          the event.
        </Styled.Disclaimer> */}
      </Container>
    </PageSection>
  );
};

export default Form;
