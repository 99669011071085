/* eslint-disable react/jsx-props-no-spreading  */
import React, { useState, useEffect, useRef } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useMedia } from 'react-use';
import { gsap } from 'gsap';

import Container from '../container';
import Modal, { VideoWrapper } from 'components/modal';
import PageSection from '../pageSection';
import Portal from '../portal';

import * as Styled from './intro.styled';

const Trailer = {
  id: 'trailer',
  name: 'trailer',
  keyArt:
    'https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/trailerKeyArt.png',
  youtubeId: 'mk08LKrdcd0',
  numero: '',
  learnMoreCta:
    'https://www.amazon.com/Bingo-Hell-Adriana-Barraza/dp/B09FSPY6QD/ref=sr_1_4?dchild=1&keywords=bingo+hall+prime+video&qid=1631824651&s=instant-video&sr=1-4'
};

const node: HTMLElement | null = document.getElementById('root');

function isTouchScreendevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

const getMousePos = (event: any) => {
  let posx = 0;
  let posy = 0;
  let e = event;
  if (!e) e = window.event;
  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }
  return { x: posx, y: posy };
};

export const HoverCallout = ({ cursorRef, isHover, ...rest }: any) => (
  <Styled.HoverContent ref={cursorRef} active={isHover} className="hovercircle" {...rest}>
    <h3>WATCH TRAILER</h3>
  </Styled.HoverContent>
);

const Intro = () => {
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const hasMouse = useMedia('(pointer: fine)');

  const isTouch = !hasMouse && !!isTouchScreendevice();

  const wrapperRef = useRef<any>(null);
  const wrapperColRef = useRef<any>(null);
  const cursorRef = useRef<any>(null);
  const tlRef = useRef<any>(null);

  // useEffect(() => {
  //   if (isTouch) {
  //     gsap.registerPlugin(ScrollTrigger);

  //     let tl = tlRef.current;

  //     tl = gsap.timeline({
  //       scrollTrigger: {
  //         id: `teaser-scrollTrigger1`,
  //         trigger: cursorRef.current,
  //         start: 'top center',
  //         end: 'bottom center',
  //         // markers: true,
  //         toggleActions: 'play reverse play reverse' // onEnter, onLeave, onEnterBack, onLeaveBack
  //         // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
  //         // scrub: true
  //       }
  //     });

  //     tl.to(cursorRef.current, {
  //       opacity: 1,
  //       ease: 'power1.out',
  //       duration: 0.65
  //     });
  //   }

  //   if (!wrapperRef || isTouch) return;
  //   const keyart = wrapperRef.current;

  //   keyart.addEventListener('mousemove', (ev: any) => {
  //     const rect = keyart.getBoundingClientRect();

  //     keyart.setAttribute('style', 'cursor: none !important;');
  //     wrapperRef!.current!.setAttribute('style', 'cursor: none !important;');
  //     cursorRef.current.setAttribute('style', `transform: translate(${rect.x}px, ${rect.y}px)`);

  //     const mousePos = getMousePos(ev);
  //     const width = rect.width;
  //     const height = rect.height;

  //     const withinBounds =
  //       mousePos.x > rect.x &&
  //       mousePos.x < rect.x + width &&
  //       mousePos.y > rect.y &&
  //       mousePos.y - window.scrollY < rect.y + height;
  //     if (!withinBounds) return;

  //     gsap.to(cursorRef.current, {
  //       x: mousePos.x,
  //       y: mousePos.y - window.scrollY,
  //       // opacity: 1,
  //       // scale: 1,
  //       ease: 'power1.out',
  //       duration: 0.35
  //     });

  //     setIsHover(true);
  //   });

  //   return () => {};
  // }, [isTouch]);

  const onClose = () => {
    setIsShowingModal(false);
  };

  const showModal = () => {
    setIsShowingModal(true);
  };

  return (
    <PageSection hasPaddingBottom={false}>
      <Portal destination={node}>
        <Modal isShowing={isShowingModal} onClose={onClose} alignment={'CENTER'}>
          <VideoWrapper>
            <Styled.VideoContainer>
              {isShowingModal && (
                <iframe
                  src={`https://www.youtube.com/embed/${Trailer.youtubeId}?autoplay=1&controls=1&rel=0`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </Styled.VideoContainer>
          </VideoWrapper>
        </Modal>
      </Portal>
      <Container hasMaxWidth>
        <Styled.Wrapper className="anim-in">
          <Styled.KeyArtWrapper onClick={showModal}>
            {/* <Styled.KeyArtWrapper
            ref={wrapperColRef}
            onClick={showModal}
            className="keyart"
            onMouseOut={() => {
              gsap.delayedCall(0.005, () => {
                console.log('setting to false');
                setIsHover(false);
              });
            }}
          > */}
            <HoverCallout onClick={showModal} />
            {/* {isTouch ? (
              <HoverCallout
                onClick={showModal}
                cursorRef={cursorRef}
                isHover={isHover}
                isTouch={true}
              />
            ) : (
              <Styled.HoverWrapper onClick={showModal} ref={wrapperRef} active={isHover}>
                <Portal destination={node}>
                  <HoverCallout cursorRef={cursorRef} isHover={isHover} isTouch={false} />
                </Portal>
              </Styled.HoverWrapper>
            )} */}
            <Styled.KeyArt src={Trailer.keyArt} />
          </Styled.KeyArtWrapper>
          <Styled.Heading>a live Virtual EXPERIENCE</Styled.Heading>
          <Styled.SubHeading hasMargin margin="0.2em 0">
            CELEBRATING 4 new BLUMHOUSE Films
          </Styled.SubHeading>
          <Styled.Copy hasMargin margin="1.8em 0 0">
            Blumhouse Live is a virtual immersive experience that combines elements from 4 all new
            Blumhouse films. Make sure to RSVP and watch the films in order to find clues before you
            enter Blumhouse Live for <strong>ONE NIGHT ONLY</strong> on <strong>OCT 14</strong>.
          </Styled.Copy>
        </Styled.Wrapper>
      </Container>
    </PageSection>
  );
};

export default Intro;
