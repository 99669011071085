import { css } from 'styled-components';

export const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 0, // rem
    outerMargin: 10, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 75 // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75 // em
    }
  }
};

export const secondaryThemeColor = '#08A4EE';

export const focusBox = '0 0 0 2px rgba(27, 127, 204, 0.8)';

const base = 8;
const baseFontSize = 16;
const baseLeading = 1.333;

export const spacing = {
  base,
  small: base * 2,
  medium: base * 3,
  large: base * 5,
  huge: base * 8
};

export const colors = {
  primary: '#3b3b3b',
  secondary: '#b9b9b9',
  tertiary: '',
  buttonActive: '#006AEB',
  buttonBg: '#070707',
  text: '#333333',
  textMid: '#666666',
  textSubtle: '#999999',
  textReverse: '#ffffff',
  success: '#4ED98C',
  error: '#fd4c4c',
  divider: 'rgba(255, 255, 255, .4)'
};

export const chat = {
  backgroundDark: 'rgba(0, 0, 0, 0.3)',
  backgroundLight: '#ffffff',
  darkChat: '#00000',
  lightMode: '#DCDCDC',
  lightModeChat: '#EFEEED',
  darkModeChat: '#2b2b2b',
  darkModeBorder: '#434343',
  darkModeBackground: 'rgba(255, 255, 255)',
  darkScrollbar: 'rgba(255, 255, 255, 0.2)',
  lightScrollbar: '#E4E3E2',
  white: 'white',
  black: 'black'
};

const primaryFont = 'Helvetica';
const secondaryFont = 'Helvetica';
const tertiaryFont = 'Helvetica';
const fallbackFont = 'Inter';

export const fontFamily = {
  default: primaryFont,
  boldText: '',
  buttonText: secondaryFont,
  fallback: fallbackFont,
  inputText: secondaryFont,
  linkText: secondaryFont,
  paragraphText: secondaryFont,
  primaryHeaderText: primaryFont,
  secondaryHeaderText: secondaryFont,
  tertiaryHeaderText: tertiaryFont
};

export const fontSizes = {
  h1: baseFontSize * 2,
  h2: baseFontSize * 1.5,
  h3: baseFontSize * 1.177,
  h4: baseFontSize,
  h5: baseFontSize * 0.833,
  h6: baseFontSize * 0.666,
  p: baseFontSize,
  mobilep: baseFontSize * 0.75,
  default: baseFontSize,
  header: 68,
  input: baseFontSize,
  link: baseFontSize,
  small: baseFontSize * 0.75,
  countdown: 48
};

export const fontWeights = {
  h1: 700,
  h2: 700,
  h3: 700,
  h4: 700,
  h5: 700,
  h6: 700,
  p: 400
};

export const fontLeading = {
  h1: baseLeading * fontSizes.h1,
  h2: baseLeading * fontSizes.h2,
  h3: baseLeading * fontSizes.h3,
  h4: baseLeading * fontSizes.h4,
  h5: baseLeading * fontSizes.h5,
  h6: baseLeading * fontSizes.h6,
  p: baseLeading * fontSizes.p,
  mobilep: baseLeading * fontSizes.mobilep,
  default: baseLeading * fontSizes.default,
  header: baseLeading * fontSizes.header,
  input: baseLeading * fontSizes.input,
  link: baseLeading * fontSizes.link,
  countdown: baseLeading * fontSizes.countdown
};

const columnStart = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const columnEnd = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const columnCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// TODO: Propose new convention for flex alignments
// direction-justify-align
// TODO: Rename to columnCenter
const columnCenterCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const spaceCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const spaceStart = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const spaceEvenly = css`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const rowStart = css`
  display: flex;
  justify-content: center;
  align-items: start;
`;

const spaceAround = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const spaceEnd = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const alignEnd = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const flexbox = {
  spaceAround,
  spaceBetween,
  spaceCenter,
  spaceEnd,
  spaceEvenly,
  spaceStart,
  columnCenter,
  columnCenterCenter,
  columnStart,
  columnEnd,
  rowStart,
  alignEnd
};

export const roundedBorderCSS = css`
  border-radius: 8px;
`;
export const boxShadowCSS = css`
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);
`;
export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletXXS: 600,
  tabletXS: 768,
  tabletS: 810,
  tablet: 900,
  tabletL: 1024,
  laptopS: 1200,
  laptop: 1330,
  laptopL: 1440,
  desktop: 1441,
  desktopM: 1536,
  desktopSL: 1680,
  desktopL: 2500,
  desktopXL: 4000
};

export const device = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  mobileLandScape:
    'screen and (orientation:landscape) and (min-device-width: 319px) and (max-device-width: 880px)',
  tabletXXS: `(max-width: ${size.tabletXXS}px)`,
  tabletXS: `(max-width: ${size.tabletXS}px)`,
  tabletS: `(max-width: ${size.tabletS}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  tabletL: `(max-width: ${size.tabletL}px)`,
  laptopS: `(max-width: ${size.laptopS}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopM: `(min-width: ${size.desktopM}px) and (max-width: ${size.desktopSL - 1}px)`,
  desktopSL: `(min-width: ${size.desktopSL}px) and (max-width: ${size.desktopL - 1}px)`,
  desktopL: `(min-width: ${size.desktopL}px)  and (max-width: ${size.desktopXL - 1}px)`,
  desktopXL: `(min-width: ${size.desktopXL}px)`
};

export const deviceSizes = {
  /* Supports up to iPhone 12 Max is 428 x 926 Pixels
   For reference - OnePlus Nord (2020) Android 10.0 is 412px width x 915px height
*/
  mobileDeviceWidth: 430,
  mobileDeviceHeight: 930,
  /* Supports up to iPad Pro 10.5 inch which is 834px width x 1112px height */
  tabletDeviceWidth: 849,
  tabletDeviceHeight: 1115,
  tabletProDeviceWidth: 1024,
  tabletProDeviceHeight: 1366
};

export const defaultTransition = '300ms ease-in-out';
