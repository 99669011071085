import styled from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';

export const FooterSection = styled.div`
  padding: 0 var(--spacing-y-3) var(--spacing-y-6);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
    max-width: 714px;
  }
`;

export const FooterLinks = styled.div`
  padding: var(--spacing-y-3) var(--spacing-y-2) 0;
  font-family: var(--font-family-secondary);
  font-weight: 300;

  span {
    padding: 0 var(--spacing-y-1);
  }
`;

export const Link = styled.a`
  font-style: normal;
  font-size: ${`clamp(${pxToEm(12)}em, 3vw, ${pxToEm(14)}em)`};
  line-height: 100%;
  letter-spacing: 0.001em;
`;

export const Byline = styled.div`
  padding-top: var(--spacing-y-6);

  a {
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  svg {
    opacity: 0.77;

    @media ${device('min').small} {
      width: 100px;
      height: auto;
    }

    path {
      fill: white;
    }
  }
`;

export const LogoImage = styled.div`
  padding-top: var(--spacing-y-1);
`
