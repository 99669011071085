/* eslint-disable react/jsx-props-no-spreading  */
import React from 'react';
import * as Styled from './pageSection.styled';

export interface IPageSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  bgColor?: string;
  children: React.ReactNode;
  /** Turn on padding top */
  hasPaddingTop?: boolean;
  /** Turn on padding bottom */
  hasPaddingBottom?: boolean;
  /** If a page section contains elements that need to be full width,
   * use in this flag along with `InnerSection` components to build the section */
  hasInnerSections?: boolean;
}

const PageSection = React.forwardRef(
  (
    { children, color, bgColor, hasPaddingTop, hasInnerSections, ...restProps }: IPageSectionProps,
    ref: any
  ) => {
    return (
      <Styled.PageSection
        color={color}
        bgColor={bgColor}
        hasPaddingTop={hasPaddingTop}
        hasInnerSections={hasInnerSections}
        ref={ref}
        {...restProps}
      >
        {children}
      </Styled.PageSection>
    );
  }
);

PageSection.defaultProps = {
  color: 'currentColor',
  bgColor: 'none',
  hasPaddingTop: false,
  hasPaddingBottom: true,
  hasInnerSections: false
};

export default PageSection;
