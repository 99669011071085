import styled from 'styled-components';
import { flexbox } from 'styles/variables';
import { Alignment } from './modal.types';

export const Wrapper = styled.div<any>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100vw;
  z-index: 100000;
  pointer-events: ${({ isShowing }) => (isShowing ? 'all' : 'none')};
  ${({ alignment }) => (alignment === Alignment.BOTTOM ? flexbox.alignEnd : flexbox.spaceCenter)};
  visibility: hidden;
  display: none;
  color: #fff;
`;

export const Content = styled.div<any>`
  position: relative;
  width: calc(100vw);
  border-radius: 5px;
  z-index: 5;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.85);
`;

export const VideoWrapper = styled.div`
  padding: var(--spacing-y);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    height: auto;
    max-width: 130vh;
  }
`;

export default Wrapper;
