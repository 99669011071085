import styled from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';
import Typography from '../typography';

import Button from '../button';

const { H1, H2, PG } = Typography;

export const FormSection = styled.div<any>`
  padding: var(--spacing-y-2);
`;

export const FormWrapper = styled.div<any>`
  text-align: center;
  width: 100%;
  padding: var(--spacing-y-3);
  border: 1px solid var(--red-stroke);
  background: var(--body-bg-color);
`;

export const Form = styled.form<any>``;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderGroup = styled.div<any>`
  padding-bottom: var(--spacing-y-2);
`;

export const SupHeading = styled(H2)<any>`
  font-size: ${`clamp(${pxToEm(18)}em, 5vw, ${pxToEm(34)}em)`};
`;

export const Heading = styled(H1)<any>`
  color: var(--red);
  font-size: clamp(2.0625em, 8vw, 8.0625em);

  @media only screen and (min-width: 2160px) {
    font-size: clamp(2.0625em, 8vw, 6.0625em);
  }
`;

export const SubHeading = styled(PG)<any>`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: clamp(0.875em, 3vw, 1.75em);
`;

export const Disclaimer = styled(PG)<any>`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: clamp(0.75em, 5vw, 0.875em);
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
`;

export const InputGroup = styled.div<any>`
  position: relative;
  width: 100%;
  padding-bottom: var(--spacing-y-2);
`;

export const ErrorMessage = styled.div<any>`
  margin: 4px 20px 0;
  font-size: clamp(0.0625em, 3vw, 1.5em);
  color: var(--accent-important);
  text-align: ${({ align }) => (align ? align : 'left')};
`;

export const PGSmall = styled(PG)<any>`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: clamp(0.875em, 3vw, 1.75em);
  font-size: ${`clamp(${pxToEm(14)}em, 5vw, ${pxToEm(16)}em)`};
`;

export const Success = styled.div`
  position: absolute;
  background: #0b181a;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: var(--spacing-y-3) var(--spacing-y-1);
`;

export const ShareLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing-y-2);
`;
export const ShareLink = styled.a`
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-y-1);
`;

export const ShareWrapper = styled.div`
  border-top: 1px solid rgba(196, 196, 196, 0.2);
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  padding: var(--spacing-y-2) 0;
  margin: var(--spacing-y-3) auto;
  max-width: 900px;
`;

export const SuccessHeading = styled(H1)<any>`
  color: var(--red);
  font-size: ${`clamp(${pxToEm(22)}em, 5vw, ${pxToEm(60)}em)`};
  line-height: 120%;
`;

export const CalloutWrapper = styled.div``;

export const CalloutBtn = styled(Button)`
  font-size: ${pxToEm(14)}em;
  display: inline-block;
  width: auto;
  margin-top: var(--spacing-y-3);
`;

export const Closed = styled.div`
  width: 100%;
  padding: var(--spacing-y-4);
  position: absolute;
  background: #0b181a;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const Open = styled.div``;
