import { createGlobalStyle } from 'styled-components';
import { breakpoints } from './styleFunctions';

const GlobalStyle = createGlobalStyle`

  /* --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 800;
  --font-weight-bolder: 900; */

  .sb-show-main.sb-main-padded {
    margin: 0;
    padding: 0;
    display: block;
  }

  // Oduda regular
  @font-face {
    font-family: 'Shapiro';
    src: url('https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/1db57680-4dd1-4b37-8897-e21d93771f58.woff2');
    src: url('https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/3d1a9087-5bc5-49f5-9e50-d62f48b7eac9.woff') format('woff'),
    url('https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/1db57680-4dd1-4b37-8897-e21d93771f58.woff2') format('woff2');
  }

  :root {
    /* EASING */
    --ease-in: cubic-bezier(0.49, 0.025, 0.65, 0.65);
    --ease-out: cubic-bezier(0.28, 0.44, 0.49, 1);
    --ease-in-out: cubic-bezier(0.49, 0.025, 0.49, 1);
    --bounce: cubic-bezier(0.6, 0, 0.1, 1.4);
    --ease-in-soft: cubic-bezier(0.49, 0.025, 0.685, 1);
    --ease-out-soft: cubic-bezier(0.28, 0, 0.49, 1);
    --ease-in-out-soft: cubic-bezier(0.72, 0, 0.28, 1);
    --ease-in-out-hard: cubic-bezier(0.77, 0, 0.175, 1);
    --ease-garret: cubic-bezier(0.5, 0, 0, 1);

    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 800;
    --font-weight-bolder: 900;

    --rem-base: 62.5%;
    --font-size-small: 1.2rem;
    --font-size-body: 1.6rem;
    --font-size-large: 2.0rem;
    --line-height-small: 1.14;
    --line-height-body: 1.4;
    --line-height-large: 1.45;
    --font-family: 'Shapiro', sans-serif;
    --font-family-secondary: 'Roboto', sans-serif;
    --letter-spacing: -0.01em;
    --font-size-h1: 4rem;
    --font-size-h2: 2.4rem;
    --font-size-h3: 2.2rem;
    --font-size-h4: 2rem;
    --font-size-h5: 1.8rem;
    --font-size-h6: 1.6rem;
    --line-height-h1: 0.9;
    --line-height-h2: 1.1;
    --line-height-h3: 1.1;
    --line-height-h4: 1.2;
    --line-height-h5: 1.1;
    --line-height-h6: 1.1;
    --font-weight-h1: var(--font-weight-bold);
    --font-weight-h2: var(--font-weight-bold);
    --font-weight-h3: var(--font-weight-medium);
    --font-weight-h4: var(--font-weight-bold);
    --font-weight-h5: var(--font-weight-bold);
    --font-weight-h6: var(--font-weight-medium);
    /* --letter-spacing-h1: -0.05em;
    --letter-spacing-h2: -0.05em;
    --letter-spacing-h3: -0.035em;
    --letter-spacing-h4: -0.02em;
    --letter-spacing-h5: -0.02em;
    --letter-spacing-h6: -0.02em; */

    --dark: #242424;
    --light: #fff;
    --off-white: #F5F5F5;
    --accent-dark: #212121;
    --accent-darker: #1E1E1E;
    --accent-darkest: #101010;
    --accent-primary: #00A8E1;

    --red: #CD2B1E;
    --red-stroke: #B80E14;

    --accent-secondary: #DF0EC6;

    --accent-important: #B80E14;

    --initial-body-bg-color: #0B171A;
    --body-bg-color: #0B171A;

    --border-large: 5px solid var(--accent-dark);

    --page-max-width: 1075px;

    --nav-height: 100px;
    --nav-shift: 25px;

    /** SPACING */
    --spacing-y-1: 10px;                               /* #93CAF1 */
    --spacing-y-1-5: calc(var(--spacing-y-1) * 1.5);   /* #84DBB9 */
    --spacing-y-2: calc(var(--spacing-y-1) * 2);       /* #9AD464 */
    --spacing-y-3: calc(var(--spacing-y-1) * 3);       /* #EECD59 */
    --spacing-y-4: calc(var(--spacing-y-1) * 4);       /* #FE9263 */
    --spacing-y-5: calc(var(--spacing-y-1) * 5);
    --spacing-y-6: calc(var(--spacing-y-1) * 6);       /* #FD6D6D */
    --spacing-y-7: calc(var(--spacing-y-1) * 7);
    --spacing-y-8: calc(var(--spacing-y-1) * 8);
    --spacing-y-9: calc(var(--spacing-y-1) * 9);       /* #F2536F */

    --spacing-y: 17px;
    --spacing-x: 20px; /* #E12CFF */
  }

  // 768
  @media only screen and (min-width: ${breakpoints.small}px) {
    :root {
      --spacing-x: 30px;
      --spacing-y: 20px;
    }
  }

  // 1024
  @media only screen and (min-width: ${breakpoints.medium}px) {
    :root {
      --spacing-y: 38px;
      --nav-height: 130px;
      --nav-shift: 45px;
    }
  }

  // 1440
  @media only screen and (min-width: ${breakpoints.large}px) {
    :root {
      --spacing-x: 105px;
      --spacing-y-1: 16px;                               /* #93CAF1 */
      --spacing-y: 38px;
    }
  }

  // 1440
  @media only screen and (min-width: ${breakpoints.large}px) {
    :root {
      --spacing-x: 105px;
      --spacing-y-1: 16px;                               /* #93CAF1 */
      --spacing-y: 38px;
      --page-max-width: 1240px;
    }
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: calc(var(--vh, 1vh) * 100);
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overscroll-behavior: none;
    overflow-x: hidden;
    background: var(--body-bg-color);
    font-variant-ligatures: none;
    font-size: var(--font-size-body);
    font-family: var(--font-family), sans-serif;
    color: #fff;
  }

  #root {
    height: 100%;
    min-height: 100vh;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -ms-text-size-adjust: 100%;
    font-variant-ligatures: normal;
    -webkit-font-smoothing: antialiased;
  }

  @media (min-width: 2160px) {
    :root {
      --rem-base: 82.5%;
      --page-max-width: 1640px;
    }
  }
  @media only screen and (min-width: 1280px) {
    :root {
      --font-size-small: 1.4rem;
      --font-size-body: 1.8rem;
      --font-size-large: 2.2rem;
    }
  }
  @media only screen and (min-width: 480px) {
    :root {
      --font-size-h1: 4.6rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    :root {
      --font-size-h1: 6.25vw;
    }
  }
  @media only screen and (min-width: 1280px) {
    :root {
      --font-size-h2: 4.2rem;
      --font-size-h3: 3.8rem;
      --font-size-h4: 2.8rem;
      --font-size-h5: 2.2rem;
      --font-size-h6: 2.2rem;
      --grid-padding: 8rem;
      --grid-column-count: 12;
      --grid-gutter: 2rem;
      --header-height: 12.6rem;
      --pages-section: 12.5vw;
      --caseStudies-section: 18.2vw;
      --services-section: 10.4vw;
      --blog-section: 10.4vw;
      --thinking-section: 12.5vw;
      --pages-meta-bar: 8.5rem;
      --caseStudies-meta-bar: 8.5rem;
      --services-meta-bar: 8.5rem;
      --pages-intro-small: 4.1vw;
      --pages-intro-large: 7.8vw;
    }
  }
  html {
    font-size: var(--rem-base);
    font-variant-ligatures: normal;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    font-weight: var(--font-weight-medium);
    font-family: var(--font-family);
    letter-spacing: var(--letter-spacing);
  }

  b,
  strong {
    font-weight: var(--font-weight-bold);
  }

  i {
    font-style: italic;
  }

  sup {
    margin-left: 0.1rem;
    vertical-align: super;
    top: 0;
  }

  h1 {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    font-weight: var(--font-weight-h1);
    letter-spacing: var(--letter-spacing-h1);
  }

  h2 {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
    font-weight: var(--font-weight-h2);
    letter-spacing: var(--letter-spacing-h2);
  }

  h3 {
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);
    font-weight: var(--font-weight-h3);
    letter-spacing: var(--letter-spacing-h3);
  }
  h4 {
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
    font-weight: var(--font-weight-h4);
    letter-spacing: var(--letter-spacing-h4);
  }

  h5 {
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
    font-weight: var(--font-weight-h5);
    letter-spacing: var(--letter-spacing-h5);
  }

  h6 {
    font-size: var(--font-size-h6);
    line-height: var(--line-height-h6);
    font-weight: var(--font-weight-h6);
    letter-spacing: var(--letter-spacing-h6);
  }

  .meta {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  a,
  button {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  /* Page transitions */
  .fade-enter {
    opacity: 0;
    z-index: 1;
    position: absolute;
  }

  .fade-exit {
    position: relative;
  }

  .fade-enter-done {
    position: relative;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 950ms ease-in;
    width: 100vw;
    z-index: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 950ms ease-in;
    width: 100vw;
    z-index: 2;
  }
`;

export default GlobalStyle;
