import { useEffect } from 'react';
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';
import mixpanel from 'mixpanel-browser';

import Page from 'components/page';

import useAnimateInScroll from 'hooks/useAnimateInScroll';

// function setDocHeight() {
//   document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
// }

mixpanel.init(process.env.REACT_APP_MIXPANEL || '');

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT || 'https://api.lcdigital.io'}/graphql`
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([httpLink])
});

function App() {
  useAnimateInScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ApolloClientProvider client={client}>
        <ApolloProvider client={client}>
          <Page />
        </ApolloProvider>
      </ApolloClientProvider>
    </>
  );
}

export default App;
