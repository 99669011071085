/* eslint-disable import/prefer-default-export */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import styled, { css } from 'styled-components';

import { IPageSectionProps } from './pageSection';

type StylePageSectionProps = Pick<
  IPageSectionProps,
  'bgColor' | 'color' | 'hasPaddingTop' | 'hasPaddingBottom' | 'hasInnerSections'
>;

export const PageSection = styled.div<StylePageSectionProps>`
  padding: ${({ hasPaddingTop }) => (hasPaddingTop ? 'var(--spacing-y-6)' : 0)}
    ${({ hasInnerSections }) => (hasInnerSections ? '0' : 'var(--spacing-x)')} ${({ hasPaddingBottom }) => (hasPaddingBottom ? 'var(--spacing-y-6)' : 0)};
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  overflow: hidden;
  position: relative;
`;

export const InnerSection = styled.div<StylePageSectionProps>`
  padding: 0 var(--spacing-x) 0;
`;
