/* eslint-disable react/jsx-props-no-spreading  */
import React, { ReactNode } from 'react';
import { flexbox } from '../../styles/variables';

import * as Styled from './container.styled';

export interface IContainerProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  /** If the container has a background color define it here */
  bgColor?: string;
  /** Whether or not the container has top and bottom padding */
  hasVerticalPadding?: boolean;
  /** Whether or not the container has left and right padding */
  hasHorizontalPadding?: boolean;
  /** Should the container have a max width */
  hasMaxWidth?: boolean;
  /** Specify a custom `max-width` value, otherwise use `var(--page-max-width)` set in global styles */
  maxWidth?: string;
  /** If true the items will be spaced evenly throught out the container with flex and margin `left` or `right` will align them left or right */
  spaceItems?: boolean | 'left' | 'right';
  /** The amount of margin to space the items with */
  spaceValue?: string;
  /** Flex styled from `'styles/variables'` */
  flexStyle?:
    | 'spaceAround'
    | 'spaceBetween'
    | 'spaceCenter'
    | 'spaceEnd'
    | 'spaceEvenly'
    | 'spaceStart'
    | 'columnCenter'
    | 'columnCenterCenter'
    | 'columnStart'
    | 'columnEnd'
    | 'rowStart'
    | 'alignEnd';
}

const Container = React.forwardRef(
  (
    {
      children,
      bgColor,
      flexStyle,
      spaceItems,
      spaceValue,
      hasMaxWidth,
      maxWidth,
      ...restProps
    }: IContainerProps,
    ref: any
  ) => {
    return (
      <Styled.Container
        ref={ref}
        bgColor={bgColor}
        flex={flexStyle && flexbox[flexStyle]}
        spaceItems={spaceItems}
        spaceValue={spaceValue}
        hasMaxWidth={hasMaxWidth}
        maxWidth={maxWidth}
        {...restProps}
      >
        {children}
      </Styled.Container>
    );
  }
);

Container.defaultProps = {
  bgColor: undefined,
  flexStyle: undefined,
  spaceValue: undefined,
  hasVerticalPadding: false,
  hasHorizontalPadding: false,
  hasMaxWidth: false,
  maxWidth: undefined,
  spaceItems: false
};

export default Container;
