import styled, { css } from 'styled-components';

interface IPGProps {
  size?: 'default' | 'small' | 'large';
  hasMargin?: boolean;
  hasLineHeight?: boolean;
  muted?: boolean;
  margin?: string;
  lineHeight?: string;
  weight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number | 'initial' | 'inherit' | string;
}

interface IHProps {
  hasMargin?: boolean;
  hasLineHeight?: boolean;
  margin?: string;
  lineHeight?: string;
  /** Refers to an alternate style for the heading */
  isAlt?: string;
  weight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number | 'initial' | 'inherit' | string;
  isOutline?: boolean;
}

const markStyles = css`
  mark {
    background: none;
    color: currentColor;
    text-transform: none;
  }
`;

const H1 = styled.h1<IHProps>`
  ${({ hasMargin = false, margin = '1.2em 0' }) => hasMargin && `margin: ${margin}`};
  font-weight: var(--font-weight-bolder);
  line-height: ${({ hasLineHeight = false, lineHeight = '110%' }) => (hasLineHeight ? `${lineHeight}` : '100%')};
  letter-spacing: 0.02em;
  font-size: clamp(2.625em, 3vw, 3.333em);
  text-transform: uppercase;

  font-kerning: none;
  -webkit-text-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  ${markStyles}
`;

const outlineStyles = css`
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  -webkit-text-stroke-width: 1.5px;
  color: transparent;
  font-size: clamp(2.3em, 6vw, 4.643em);
`;

const thinStyles = css`
  font-style: normal;
  font-weight: var(--font-weight-light);
  font-size: clamp(2.2em, 4vw, 2.833em);
  line-height: 115%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const H2 = styled.h2<IHProps>`
  ${({ hasMargin = false, margin = '1.2em 0' }) => hasMargin && `margin: ${margin}`};
  font-weight: ${({ weight = 'bolder' }) => weight && `var(--font-weight-${weight})`};
  line-height: ${({ hasLineHeight = false, lineHeight = '110%' }) => (hasLineHeight ? `${lineHeight}` : '100%')};
  letter-spacing: 0.02em;
  text-transform: uppercase;

  font-size: clamp(1.31em, 6vw, 2.625em);

  ${({ isOutline }) => isOutline && outlineStyles};
  ${({ isAlt }) => isAlt && thinStyles};
  -webkit-text-stroke-color: ${({ color }) => color || 'var(--accent-dark)'};
  ${markStyles}
`;

const H3 = styled.h3<IHProps>`
  ${({ hasMargin = false, margin = '1.2em 0' }) => hasMargin && `margin: ${margin}`};
  font-weight: var(--font-weight-bolder);
  line-height: ${({ hasLineHeight = false, lineHeight = '110%' }) => (hasLineHeight ? `${lineHeight}` : '100%')};
  letter-spacing: 0.02em;
  font-size: clamp(1.65em, 2vw, 3em);
  ${markStyles}
`;

const H4 = styled.h4<IHProps>`
  ${({ hasMargin = false, margin = '1.2em 0' }) => hasMargin && `margin: ${margin}`};
  font-weight: var(--font-weight-bolder);
  line-height: ${({ hasLineHeight = false, lineHeight = '110%' }) => (hasLineHeight ? `${lineHeight}` : '100%')};
  letter-spacing: 0.02em;
  font-size: clamp(1.24em, 2vw, 1.667em);
  ${markStyles}
`;

const H5 = styled.h5<IHProps>`
  margin: ${({ hasMargin = false, margin = '1.2em 0' }) => (hasMargin ? `${margin}` : 0)};
  font-weight: var(--font-weight-bold);
  line-height: ${({ hasLineHeight = false, lineHeight = '110%' }) => (hasLineHeight ? `${lineHeight}` : '100%')};
  letter-spacing: 0.02em;
  font-size: clamp(1.43em, 2vw, 2.071em);
  ${markStyles}
`;

const HGroup = styled.hgroup<any>`
  ${({ hasMargin = true, margin = 'var(--spacing-y-6) 0' }) => hasMargin && `margin: ${margin}`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const pgDefault = css`
  font-size: clamp(1em, 2vw, 1.3em);
`;

const pgSmall = css`
  font-size: clamp(0.75em, 2vw, 1.15em);
`;

const pgLarge = css`
  font-size: clamp(1.375em, 2vw, 2.16em);
`;

const mutedStyles = css`
  opacity: 0.6;
`;

const PG = styled.p<IPGProps>`
  font-family: var(--font-family-secondary);
  font-weight: ${({ weight = 'regular' }) => weight && `var(--font-weight-${weight})`};
  line-height: ${({ lineHeight }) => lineHeight || '150%'};
  ${({ muted = false }) => muted && mutedStyles};
  ${({ hasMargin = false, margin = '1.2em 0' }) => hasMargin && `margin: ${margin}`};

  ${({ size = 'default' }) => {
    switch (size) {
      case 'small':
        return pgSmall;
      case 'large':
        return pgLarge;
      default:
        return pgDefault;
    }
  }}
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  PG,
  HGroup
};
