import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  /**
   * The destination element for children of `Modal`.
   */
  destination: HTMLElement | undefined | null;
}

type Props = PropsWithChildren<ModalProps>;

/**
 * Insert children of `Modal` into the element given as `destination`. If
 * `destination` is not defined render as `null`.
 */
function Portal(props: Props) {
  const { destination } = props;
  if (destination) {
    return createPortal(props.children, destination);
  }

  return null;
}

export default Portal;
