import React, { useCallback, useRef, useEffect, useState, MutableRefObject } from 'react';
import useOverlayAnimation from 'hooks/useOverlayAnimation';
import CloseButton from 'components/closeButton';

import * as Styled from './modal.styled';
import { Alignment } from './modal.types';

const Modal = ({ onClose, isShowing, alignment = Alignment.BOTTOM, children }: any) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const [contentVisible, setContentVisible] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const animationStart = () => {
    wrapperRef!.current!.style.visibility = 'visible';
    wrapperRef!.current!.style.display = 'flex';

    setContentVisible(true);
  };

  const animationEnd = () => {
    wrapperRef!.current!.style.visibility = 'hidden';
    wrapperRef!.current!.style.display = 'none';
    setContentVisible(false);
  };

  const timeline: MutableRefObject<any> = useOverlayAnimation({
    wrapperRef,
    overlayRef,
    contentRef,
    callbacks: {
      onStart: animationStart,
      onReverseComplete: animationEnd
    }
  });

  useEffect(() => {
    const tlRefCurrent = timeline?.current;

    if (isShowing && tlRefCurrent) {
      tlRefCurrent.timeScale(1).play();
    } else if (tlRefCurrent) {
      tlRefCurrent.timeScale(3).reverse();
    }

    return () => {
      if (tlRefCurrent) tlRefCurrent.kill();
    };
  }, [isShowing, timeline]);

  return (
    <Styled.Wrapper isShowing={isShowing} alignment={alignment} ref={wrapperRef}>
      <Styled.Overlay ref={overlayRef} onClick={handleClose} />
      <CloseButton onClick={onClose} />
      <Styled.Content ref={contentRef} isShowing={isShowing}>
        {contentVisible && children}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default Modal;
