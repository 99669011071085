import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_EVENT_STATUS = gql`
  query getEvent($id: String!) {
    event(id: $id, stagingFlag: false) {
      eventStatus
      stagingEventStatus
      rsvpPage {
        content
      }
    }
  }
`;

const useEventStatus = (id: string) => {
  const { data } = useQuery(GET_EVENT_STATUS, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
    pollInterval: 25000
  });
  return {
    eventStatus: data?.event.eventStatus,
    isOverlayOpen: data?.event?.rsvpPage?.content?.isOverlayOpen
  };
};

export default useEventStatus;
