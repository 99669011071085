/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const maxWidthStyles = (maxWidth: string) => css`
  width: 100%;
  max-width: ${maxWidth || 'var(--page-max-width)'};
  margin-left: auto;
  margin-right: auto;
`;

const spacedItemStyles = (spaceType: boolean | 'left' | 'right', spaceValue: string) => {
  const marginVal = spaceValue || '2.2em';

  return css`
  > * {
    ${spaceType === 'left' && `margin-left: ${marginVal};`}
    ${spaceType === 'right' && `margin-right: ${marginVal};`}
    ${spaceType === true && `margin: 0 ${marginVal};`}
  }
`;
};

export const Container = styled.div<any>`
  width: 100%;
  padding: ${({ hasVerticalPadding }) => (hasVerticalPadding ? 'var(--spacing-y-3)' : 0)} ${({
  hasHorizontalPadding
}) => (hasHorizontalPadding ? 'var(--spacing-x)' : 0)};
  ${({ flex }) => flex && flex}
  ${({ hasMaxWidth, maxWidth }) => hasMaxWidth && maxWidthStyles(maxWidth)}
  ${({ spaceItems, spaceValue }) => spaceItems && spacedItemStyles(spaceItems, spaceValue)}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`}
`;
