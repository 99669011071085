/* eslint-disable react/jsx-props-no-spreading  */
import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import { useMedia } from 'react-use';
import Container from '../container';
import Modal, { VideoWrapper } from '../modal';
import Portal from '../portal';

import * as Styled from './teaser.styled';

function isTouchScreendevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

const getMousePos = (event: any) => {
  let posx = 0;
  let posy = 0;
  let e = event;
  if (!e) e = window.event;
  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }
  return { x: posx, y: posy };
};

const films = [
  {
    id: 'film1',
    name: 'Black as Night',
    keyart:
      'https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/black-as-night%402x.jpg',
    youtubeId: 'iuXnGbxTxDE',
    numero: '',
    watchNowCta:
      'https://www.amazon.com/Black-As-Night-Asjha-Cooper/dp/B09FXFN4W6/ref=sr_1_3?dchild=1&keywords=black+as+night+prime+video&qid=1631824665&s=instant-video&sr=1-3',
    watchNowCopy: 'Watch Black as Night'
  },
  {
    id: 'film2',
    name: 'Bingo Hell',
    keyart:
      'https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/bingo-hell%402x.jpg',
    youtubeId: 'm1DQx4Z-QPU',
    numero: '',
    watchNowCta:
      'https://www.amazon.com/Bingo-Hell-Adriana-Barraza/dp/B09FSPY6QD/ref=sr_1_4?dchild=1&keywords=bingo+hall+prime+video&qid=1631824651&s=instant-video&sr=1-4',
    watchNowCopy: 'Watch Bingo Hell'
  },
  {
    id: 'film3',
    name: 'Madres',
    keyart:
      'https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/madres%402x.jpg',
    youtubeId: 'GNusqV6dUfY',
    numero: '',
    watchNowCta:
      'https://www.amazon.com/Madres-Tenoch-Huerta/dp/B09FVMCMP9/ref=sr_1_5?dchild=1&keywords=madres+prime+video&qid=1631824635&s=instant-video&sr=1-5',
    watchNowCopy: 'Watch Madres'
  },
  {
    id: 'film4',
    name: 'Manor',
    keyart: 'https://s3.us-east-2.amazonaws.com/events.lcdigital.io/blumhouse-fonts/manor%402x.jpg',
    youtubeId: 'kKIhdhesFTk',
    numero: '',
    watchNowCta:
      'https://www.amazon.com/gp/product/B09FRLMHFM?pf_rd_r=KCJW7MTWBSX8HN047N8N&pf_rd_p=8fe9b1d0-f378-4356-8bb8-cada7525eadd&pd_rd_r=d041e5e9-0bf7-49da-8f4a-d3b543ded3ff&pd_rd_w=2cLY0&pd_rd_wg=Dr72y&ref_=pd_gw_unk',
    watchNowCopy: 'Watch Manor'
  }
];

const HoverCallout = ({ cursorRef, isHover, hoverText, ...rest }: any) => (
  <Styled.HoverContent ref={cursorRef} active={isHover} {...rest}>
    <h3>{hoverText || 'WATCH TRAILER'}</h3>
  </Styled.HoverContent>
);

const Film = ({ numeroRefs, filmRefs, openModal, filmData, index }: any) => {
  const node: HTMLElement | null = document.getElementById('root');
  const { id, numero, keyart, name, watchNowCta, watchNowCopy } = filmData;
  const hasMouse = useMedia('(pointer: fine)');
  const isTouch = !hasMouse && !!isTouchScreendevice();

  const [isHover, setIsHover] = useState<boolean>(false);

  const wrapperRef = useRef<any>(null);
  const wrapperColRef = useRef<any>(null);
  const cursorRef = useRef<any>(null);
  const tlRef = useRef<any>(null);

  // All Films are live
  useEffect(() => {
    if (isTouch) {
      gsap.registerPlugin(ScrollTrigger);

      let tl = tlRef.current;

      tl = gsap.timeline({
        scrollTrigger: {
          id: `teaser-scrollTrigger1`,
          trigger: cursorRef.current,
          start: 'top center',
          end: 'bottom center',
          // markers: true,
          toggleActions: 'play reverse play reverse' // onEnter, onLeave, onEnterBack, onLeaveBack
          // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
          // scrub: true
        }
      });

      tl.to(cursorRef.current, {
        opacity: 1,
        ease: 'power1.out',
        duration: 0.65
      });
    }

    if (!wrapperRef || isTouch) return;
    const keyart = wrapperRef.current;

    keyart.addEventListener('mousemove', (ev: any) => {
      const rect = keyart.getBoundingClientRect();

      keyart.setAttribute('style', 'cursor: none !important;');
      wrapperRef!.current!.setAttribute('style', 'cursor: none !important;');
      cursorRef.current.setAttribute('style', `transform: translate(${rect.x}px, ${rect.y}px)`);

      const mousePos = getMousePos(ev);
      const width = rect.width;
      const height = rect.height;

      const withinBounds =
        mousePos.x > rect.x &&
        mousePos.x < rect.x + width &&
        mousePos.y > rect.y &&
        mousePos.y - window.scrollY < rect.y + height;
      if (!withinBounds) return;

      gsap.to(cursorRef.current, {
        x: mousePos.x,
        y: mousePos.y - window.scrollY,
        // opacity: 1,
        // scale: 1,
        ease: 'power1.out',
        duration: 0.35
      });

      setIsHover(true);
    });

    return () => {};
  }, [isTouch]);

  return (
    <Styled.WrapperCol key={id} ref={wrapperColRef}>
      <Styled.Film>
        <Styled.Numero className="numero">
          <Styled.NumeroInner
            className="numero-inner"
            ref={(el) => (numeroRefs.current[index] = el)}
          >
            <Styled.NumeroAnimate>{numero}</Styled.NumeroAnimate>
          </Styled.NumeroInner>
        </Styled.Numero>
        <Styled.KeyArt
          ref={(el) => (filmRefs.current[index] = el)}
          className="keyart"
          onMouseOut={() => {
            gsap.delayedCall(0.005, () => {
              setIsHover(false);
            });
          }}
        >
          {isTouch ? (
            <HoverCallout
              onClick={() => window.open(watchNowCta, '_blank')}
              cursorRef={cursorRef}
              hoverText={`Watch ${name}`}
              isHover={isHover}
              isTouch={true}
            />
          ) : (
            <Styled.HoverWrapper
              onClick={() => window.open(watchNowCta, '_blank')}
              ref={wrapperRef}
              active={isHover}
            >
              <Portal destination={node}>
                <HoverCallout
                  hoverText={`Watch ${name}`}
                  cursorRef={cursorRef}
                  isHover={isHover}
                  isTouch={false}
                />
              </Portal>
            </Styled.HoverWrapper>
          )}

          <img src={keyart} alt={name} />
          <Styled.LearnMore href={watchNowCta} target="_blank">
            {watchNowCopy}
            <svg
              width="37"
              height="15"
              viewBox="0 0 37 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.4866 1.27438L34.6738 7.46156L28.4866 13.6487"
                stroke="#BB131A"
                strokeWidth="2.58621"
              />
              <path d="M34.4492 7.46158L0.51704 7.46157" stroke="#CD2B1E" strokeWidth="2.58621" />
            </svg>
          </Styled.LearnMore>
        </Styled.KeyArt>
      </Styled.Film>
    </Styled.WrapperCol>
  );
};

const Teaser = () => {
  const node: HTMLElement | null = document.getElementById('root');
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [currentTrailer, setCurrentTrailer] = useState<string>('cX8tE5XBVkE');

  const hasMouse = useMedia('(pointer: fine)');
  const isTouch = !hasMouse && !!isTouchScreendevice();

  // film refs
  const filmRefs = useRef<any>([]);
  const filmWrapperRefs = useRef<any>([]);
  const numeroRefs = useRef<any>([]);

  const wrapperRef = useRef(null);
  const lineRef = useRef(null);

  const tlRef = useRef<any>();

  const closeModal = () => {
    setIsShowingModal(false);
  };

  const openModal = (trailerId: string) => {
    setCurrentTrailer(trailerId);
    setIsShowingModal(true);
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);

    let tl = tlRef.current;

    if (!isTouch) {
      gsap.set(filmRefs.current, {
        y: '15vh'
      });
    }

    for (let i = 0; i < numeroRefs.current.length; i++) {
      const element = numeroRefs.current[i];
      let split = new SplitText(element, { type: 'words, chars', charsClass: 'point' });

      let tlSplit = gsap.timeline({
        scrollTrigger: {
          id: `teaser-split-${i}`,
          trigger: element,
          start: 'top bottom',
          end: 'bottom end',
          // markers: true,
          toggleActions: 'play none none none' // onEnter, onLeave, onEnterBack, onLeaveBack
          // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
          // scrub: true
        }
      });

      tlSplit.from(split.chars, {
        duration: 2.1,
        y: '110%',
        ease: 'power4.out',
        skewY: 7,
        stagger: {
          amount: 0.3
        }
      });
    }

    const tlLine = gsap.timeline({
      scrollTrigger: {
        id: `teaser-scrollTrigger1`,
        trigger: wrapperRef.current,
        start: 'top bottom',
        end: 'bottom end',
        // markers: true,
        toggleActions: 'play none none pause' // onEnter, onLeave, onEnterBack, onLeaveBack
        // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
        // scrub: true
      }
    });

    tlLine.from(lineRef.current, {
      scaleY: 0,
      duration: 7,
      delay: 1,
      ease: 'power1.out'
    });

    if (isTouch) return;
    tl = gsap.timeline({
      scrollTrigger: {
        id: `teaser-scrollTrigger2`,
        trigger: wrapperRef.current,
        start: 'top bottom',
        end: 'bottom end',
        // markers: true,
        toggleActions: 'restart pause reverse pause', // onEnter, onLeave, onEnterBack, onLeaveBack
        // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
        scrub: true
      }
    });

    tl.to(filmRefs.current, {
      y: '-15vh',
      duration: 1,
      stagger: {
        amount: 0.45
      }
      // ease: 'power4.out'
    });
  }, []);

  return (
    <Styled.TeaserSection hasPaddingBottom={false} ref={wrapperRef}>
      <Styled.CenterLine ref={lineRef} />
      <Container hasMaxWidth>
        <Styled.Wrapper>
          {films.map((film, index) => (
            <Film
              numeroRefs={numeroRefs}
              filmRefs={filmRefs}
              openModal={openModal}
              filmData={film}
              index={index}
              key={film.id}
            />
          ))}
        </Styled.Wrapper>
      </Container>

      <Portal destination={node}>
        <Modal isShowing={isShowingModal} onClose={closeModal} alignment={'CENTER'}>
          <VideoWrapper>
            <Styled.VideoContainer>
              {isShowingModal && currentTrailer && (
                <iframe
                  src={`https://www.youtube.com/embed/${currentTrailer}?autoplay=1&controls=1&rel=0`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </Styled.VideoContainer>
          </VideoWrapper>
        </Modal>
      </Portal>
    </Styled.TeaserSection>
  );
};

export default Teaser;
