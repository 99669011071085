/* eslint-disable consistent-return  */
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import killAnimations from 'utils/killAnimations';

const useAnimateInScroll = (callbacks?: any) => {


  const tlRefs = useRef<any>([]);
  const timeoutRef = useRef<any>(null);

  const { onEnter, onEnterBack, onLeave, onLeaveBack, onUpdate } = callbacks || {};

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const initGsapScroll = () => {

      const nodes = document.querySelectorAll('.anim-in');
      gsap.set('.anim-in', {
        opacity: 0,
        y: '100px'
      });

      for (let i = 0; i < nodes.length; i++) {

        const tl = gsap.timeline({
          scrollTrigger: {
            // id: `scrollTrigger99${i}`,
            // trigger: '.anim-in',
            trigger: nodes[i],
            // start: '0% 100%',
            start: 'top bottom',
            // markers: true,
            toggleActions: 'play none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
            // Options: "play", "pause", "resume", "reset", "restart", "complete", "reverse", and "none".
            // scrub: true
          }
        });
        tl.to(
          // '.anim-in',
          nodes[i],
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'power4.out'
          },
          'start'
        );

        tlRefs.current.push(tl);
      }
    };

    // Need to make sure the page is all rendered for correct calculations
    // a bit hacky but works for now
    timeoutRef.current = setTimeout(() => {
      initGsapScroll();
    }, 250);

    // window.addEventListener('resize', initGsapScroll);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      killAnimations(tlRefs);
      // window.removeEventListener('resize', initGsapScroll);
    };
  }, [onEnter, onEnterBack, onLeave, onLeaveBack, onUpdate, timeoutRef]);
};

export default useAnimateInScroll;
