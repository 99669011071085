/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { hexToRGBA } from 'utils/color';

const disabledColorLight = 'rgba(255, 255, 255, 0.55)';
const disabledColorDark = 'rgba(0, 0, 0, 0.35)';

const borderStyles = css`
  border: 1px solid currentColor;
  background: transparent;
`;

const loadingStyles = css<any>`
  color: transparent !important;
  position: relative;
  background-color: ${({ bgColor }) => hexToRGBA(bgColor, 0.7)};
  border: none;
  box-shadow: none;
  cursor: default;
  pointer-events: none;
  text-indent: -999em;
  display: flex;
  align-items: center;
  justify-content: center;

  @-webkit-keyframes spinAround {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes spinAround {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }

  &:after {
    -webkit-animation: spinAround 0.5s infinite linear;
    animation: spinAround 0.5s infinite linear;
    border: 2px solid #fff;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 1.6em;
    width: 1.6em;
    position: absolute;
  }
`;

const disabledStyles = css`
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
`;

const hoverStyles = css<any>`
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px ${({ bgColor }) => bgColor};
  /* color: var(--red); */
`;

const activeStyles = css<any>`
  opacity: 1;
  color: #fff;
`;

const focusStyles = css<any>`
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    box-shadow: 0px 0px 0px 3px ${({ bgColor }) => bgColor};
    ${({ roundedBorders }) => roundedBorders && 'border-radius: 10px;'}
    transform: translate(-50%, -50%);
    transform-origin: center;
  }
  :focus {
    outline: none;
  }
`;

const outlineStylesDisabled = (lightBg: boolean) => css`
  border: 1px solid ${lightBg ? disabledColorDark : disabledColorLight};
  background: transparent;
`;

const sizeSmall = css`
  padding: 16px 30px;
`;

const sizeDefault = css`
  padding: 20px 35px;
`;

const sizeLarge = css`
  padding: 20px 35px;
`;

const outlineHoverStyles = (lightBg: boolean, color: string) => css`
  color: ${lightBg ? 'var(--off-white)' : 'var(--accent-dark)'};
  background: ${color};
  border: 1px solid ${color};
`;

// const hoverStyles = () => css`
//   opacity: 0.7;
// `;

export const Button = styled.button<any>`
  position: relative;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  width: 100%;
  line-height: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--red);
  mix-blend-mode: normal;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  font-family: var(--font-family);
  font-size: clamp(0.0625em, 3vw, 1.5em);
  text-transform: uppercase;

  &:disabled {
    background-color: ${({ isLightBg }) => (isLightBg ? disabledColorDark : disabledColorLight)};
    color: ${({ isLightBg }) => (isLightBg ? disabledColorDark : disabledColorLight)};
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.66;
    ${({ isOutline, isLightBg }) => isOutline && outlineStylesDisabled(isLightBg)}
  }

  transition-property: color, background-color, opacity;
  transition-duration: 0.25s;
  transition-timing-function: var(--ease-in-hard);

  ${({ size }) => {
    switch (size) {
      case 'small':
        return sizeSmall;
      case 'large':
        return sizeLarge;
      default:
        return sizeDefault;
    }
  }}

  ${({ isOutline }) => isOutline && borderStyles}

  @media (hover: hover) {
    &:hover {
      ${({ isOutline, isLightBg, color }) =>
        isOutline ? outlineHoverStyles(isLightBg, color) : hoverStyles}
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${hoverStyles}
    }
  }

  &:focus {
    ${focusStyles}
  }

  &:active {
    ${activeStyles}
  }

  ${({ isLoading }) => isLoading && loadingStyles}

  &:disabled {
    ${disabledStyles}
  }
`;
