/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as Styled from './textInput.styled';
import * as FormStyled from 'components/form/form.styled';

import { ITextInputProps } from './textInput.types';

const TextInput = ({
  errors,
  register,
  name,
  placeholder,
  validation,
  validateMessage,
  ...restProps
}: ITextInputProps) => {
  const requiredCustomErrorMessage =
    errors[name]?.type === 'required'
      ? 'This field is required'
      : validateMessage && validateMessage;

  return (
    <FormStyled.InputWrapper>
      <Styled.StyledInput
        placeholder={validation.required ? `${placeholder} *` : placeholder}
        id={name}
        name={name}
        validationError={errors[name]}
        {...register(name, { ...validation })}
        aria-label={name}
        aria-invalid={errors[name] ? 'true' : 'false'}
        aria-required={validation.required ? 'true' : 'false'}
        {...restProps}
      />
      {errors[name] && (
        <FormStyled.ErrorMessage>
          {errors[name].message ? errors[name].message : requiredCustomErrorMessage}
        </FormStyled.ErrorMessage>
      )}
    </FormStyled.InputWrapper>
  );
};

TextInput.defaultProps = {
  validateMessage: '',
  roundedBorder: false
};

export default TextInput;
