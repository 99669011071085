import styled, { css } from 'styled-components';
import { device, pxToEm } from 'styles/styleFunctions';
import PageSection from '../pageSection';

export const TeaserSection = styled(PageSection)`
  --offsetClamp: clamp(100px, 35vh, 600px);
  padding-bottom: 0;

  @media ${device('min').small} {
    padding-bottom: var(--spacing-y-6);
  }

  @media ${device('min').medium} {
    --offsetClamp: clamp(100px, 50vh, 1200px);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-top: var(--spacing-y-9);

  @media ${device('min').small} {
    padding-bottom: calc(var(--offsetClamp) / 2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`;

export const Film = styled.div`
  width: 100%;
  position: relative;
`;

export const Numero = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${`clamp(${pxToEm(38)}em, 6vw, ${pxToEm(65)}em)`};
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  color: rgba(0, 0, 0, 0.075);
  position: absolute;
  transform: translateY(-50%);
  z-index: 5;
  bottom: calc(100% + 10px);
  right: 50%;
  pointer-events: none;
  width: 100%;
  z-index: 1;

  @media ${device('min').small} {
    top: 50%;
    right: 0;
    bottom: auto;
  }

  @media ${device('max').small} {
    display: none;
  }
`;

export const NumeroInner = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: '';
    width: 50%;
    height: 2px;
    border-top: 1px dashed var(--red-stroke);
    border-bottom: 1px dashed var(--red-stroke);
    position: absolute;
    right: 0;
    top: -1px;
    z-index: 1;
  }
`;

export const NumeroAnimate = styled.div`
  overflow-y: hidden;
`;

export const KeyArt = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;

  @media ${device('min').small} {
    width: 90%;
  }

  @media ${device('min').mediumLarge} {
    transform: translateX(-60px);
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const WrapperCol = styled.div`
  padding: var(--spacing-y-9) 0;

  @media ${device('min').small} {
    /* margin-bottom: calc(var(--offsetClamp) * -1); */
  }

  &:nth-child(even) {
    @media ${device('min').small} {
      padding-top: var(--offsetClamp);
      margin-bottom: calc(var(--offsetClamp) * -1);
    }

    .keyart {
      @media ${device('min').small} {
        margin-left: auto;
      }

      @media ${device('min').mediumLarge} {
        transform: translateX(60px);
      }
    }

    .numero {
      left: 50%;
      right: auto;

      @media ${device('min').small} {
        left: 0;
      }

      .numero-inner {
        padding-left: 15px;

        &:after {
          right: auto;
          left: 0;
        }

        &:before {
          right: calc(100% - 4px);
          left: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .numero-inner {
    padding-right: 15px;

    &:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      border-radius: 50%;

      left: calc(100% - 4px);
      top: 50%;
      transform: translateY(-50%);

      background: #0a1719;
      border: 1px solid #cd2b1e;
      box-sizing: border-box;
      z-index: 3;
    }
  }

  &:last-of-type {
    @media ${device('min').small} {
      padding-bottom: 0;
    }
  }
`;

export const CenterLine = styled.div`
  background: var(--red-stroke);
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: top;
`;

export const HoverWrapper = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: center center;
  will-change: transform;
  /* background: red; */
`;

const mobileHoverContentStyles = css`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;

const pointerHoverContentStyles = (active: any) => css`
  opacity: ${active ? '1' : '0'};
  transition: opacity 0.3s ease 0.15s;
  position: fixed;
  top: -96px;
  left: -96px;
  transform-origin: center center;
  pointer-events: none;
`;

export const HoverContent = styled.div<any>`
  /* will-change: transform; */
  border: 1px solid var(--red-stroke);
  border-radius: 50%;
  width: 192px;
  height: 192px;
  z-index: 9;

  display: flex;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(12px);
  opacity: 0;

  ${({ isTouch, active }) =>
    isTouch ? mobileHoverContentStyles : pointerHoverContentStyles(active)};

  /* Fallback for Firefox */
  background-color: rgba(0, 0, 0, 0.45);

  /* if backdrop support: transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(12px);
    background-color: transparent;
  }

  h3 {
    text-align: center;
    font-weight: 800;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  z-index: 5;
  position: relative;

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
  }

  &:after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const LearnMore = styled.a`
  position: absolute;
  top: calc(100% + var(--spacing-y-2));
  left: 0;
  font-size: 19.7879px;
  line-height: 23px;
  text-align: right;
  background: none;
  border: none;
  border-bottom: 2px solid var(--red);
  text-decoration: none;

  color: var(--red);
  padding-left: 0;
  padding-right: 0;

  font-size: clamp(0.475em, 3vw, 1.1875em);

  @media ${device('max').small} {
    // styles for 767px wide viewport and DOWN
    span {
      display: none;
    }

    svg {
      width: 20px;
      height: auto;
    }
  }

  &:hover {
    color: #fff;
    border-color: #fff;
    /* border: none; */

    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;
